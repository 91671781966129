import { db } from '@modules/firebase/config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface Params {
  facility: Facility;
  court: Court;
}

export const useSmartLock = ({ facility, court }: Params) => {
  const [loading, setLoading] = useState(true);
  const [smartLocks, setSmartLocks] = useState<SmartLock[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!facility?.id) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const fetchSmartLocks = async () => {
      try {
        const smartLocksRef = collection(db, 'smartLocks');
        const q = query(smartLocksRef, where('facilityId', '==', facility.id));

        const querySnapshot = await getDocs(q);
        const locks: SmartLock[] = [];

        querySnapshot.forEach((doc) => {
          locks.push({
            id: doc.id,
            ...doc.data(),
          } as SmartLock);
        });

        setSmartLocks(locks);
      } catch (err) {
        console.error('Error fetching smart locks:', err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchSmartLocks();
  }, [facility?.id]);

  return { smartLocks, loading, error };
};

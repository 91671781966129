import {
  useActiveCourt,
  useCurrentDate,
  useGenerateGrid,
  useGenerateHoursList,
  useIsDestructiveMode,
} from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import React, { FC, useMemo } from 'react';
import { BookingDaySummary } from '../booking-day-summary';
import { BookingSlot } from '../booking-slot/BookingSlot';
import { DesctructiveBookingSlot } from '../booking-slot/DesctructiveBookingSlot';
import { BookingWeekSummary } from '../booking-week-summary';
import { styles } from './bookingDaySlots.styles';

export const BookingDaySlots: FC = () => {
  const currentDate = useCurrentDate();
  const fascility = useFacility();
  const activeCourt = useActiveCourt();
  const timeRange = useMemo(() => {
    const court = fascility.courts.find(
      (court) => court.id === activeCourt?.id,
    );
    return court?.adminTimeRanges?.[0] || court?.timeRanges?.[0];
  }, [fascility.courts, activeCourt?.id]);
  const isDestructive = useIsDestructiveMode();
  const dayOfSlot = useMemo(
    () => format(currentDate, 'iiii', { locale: enUS }),
    [currentDate],
  );
  const { adminWorkingHours, workingHours, workSchedules } = useMemo(
    () => fascility.workDays,
    [fascility.workDays],
  );
  const hours = useMemo(
    () => adminWorkingHours?.[dayOfSlot] || workingHours?.[dayOfSlot],
    [adminWorkingHours, dayOfSlot, workingHours],
  );
  const { list } = useGenerateHoursList(
    hours,
    fascility.interval,
    currentDate,
    timeRange,
    workSchedules,
  );
  const { gridList } = useGenerateGrid(
    fascility.interval,
    currentDate,
    timeRange,
  );

  return (
    <>
      <div css={styles.base}>
        <div css={styles.wrapper}>
          <div css={styles.list(gridList)}>
            {list?.map(({ from, to, isAvailable, price }, index) => (
              <React.Fragment key={from.toString()}>
                {!isDestructive ? (
                  <BookingSlot
                    key={from.toString()}
                    from={from}
                    to={to}
                    isAvailable={isAvailable}
                    price={price}
                    isLast={index === list.length - 1}
                  />
                ) : (
                  <DesctructiveBookingSlot
                    key={from.toString()}
                    from={from}
                    to={to}
                    isAvailable={isAvailable}
                    price={price}
                    isLast={index === list.length - 1}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <BookingDaySummary />
      <BookingWeekSummary isDayView />
    </>
  );
};

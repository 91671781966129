import { FieldValuesFromFieldErrors } from '@hookform/error-message';
import { getSelectStyles } from '@shared/components/select/react-select.styles';
import { useMultipleSelect } from '@shared/hooks';
import { FieldWrapper } from '@ui/FormInput';
import {
  Controller,
  FieldErrors,
  FieldName,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select from 'react-select';

interface Option<T> {
  value: T;
  label: string;
}

interface MultipleSelectProps<TFieldValues extends FieldValues, T = string> {
  name: Path<TFieldValues>;
  labelId?: string;
  placeholderId?: string;
  options: Option<T>[];
  isRequired?: boolean;
  isSearchable?: boolean;
  rules?: UseControllerProps<TFieldValues>['rules'];
  onChangeHandler?: (value: T[]) => void;
  controlMinWidth?: number;
}

export const MultipleSelect = <TFieldValues extends FieldValues, T = string>({
  name,
  labelId,
  placeholderId = 'input.select.placeholder',
  options,
  isRequired = false,
  isSearchable = true,
  rules,
  controlMinWidth,
  onChangeHandler,
}: MultipleSelectProps<TFieldValues, T>) => {
  const { formatMessage } = useIntl();

  const {
    form,
    errors,
    handleFocus,
    handleMultiSelectChange,
    getMultiValue,
    getFieldRules,
  } = useMultipleSelect<TFieldValues, T>({
    name,
    options,
    isRequired,
    rules,
    onChangeHandler,
  });

  return (
    <FieldWrapper<TFieldValues>
      name={
        name as unknown as FieldName<
          FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>
        >
      }
      labelId={labelId}
      isRequired={isRequired}
      errors={errors}
    >
      <Controller<TFieldValues>
        name={name}
        control={form.control}
        rules={getFieldRules()}
        defaultValue={
          (Array.isArray(form.getValues(name))
            ? form.getValues(name)
            : []) as PathValue<TFieldValues, Path<TFieldValues>>
        }
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            onChange={handleMultiSelectChange(field)}
            value={getMultiValue(field.value)}
            onFocus={handleFocus}
            styles={getSelectStyles(Boolean(errors[name]), controlMinWidth)}
            id={name}
            placeholder={formatMessage({
              id: placeholderId,
            })}
            noOptionsMessage={() =>
              formatMessage({
                id: 'input.select.noResults',
              })
            }
            isMulti
            isSearchable={isSearchable}
            className="w-full"
            classNamePrefix="react-select"
          />
        )}
      />
    </FieldWrapper>
  );
};

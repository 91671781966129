import { bookingFirestoreConverter } from '@modules/booking-new';
import { FirebaseService } from '@modules/firebase';
import { Query, collection, orderBy, query, where } from 'firebase/firestore';
import { FILTER_RESULTS } from '../const';

interface BuilderQueryProps {
  statusFilter: string;
  facilityId: string;
  locale: string;
}

/**
 * Builds a Firestore query based on the given filter criteria.
 *
 * @param {BuilderQueryProps} props - The properties used to build the query.
 * @param {string} props.statusFilter - The current filter selected by the user, determining which bookings to fetch.
 * @param {string} props.facilityId - The ID of the facility to fetch bookings from.
 * @param {string} props.locale - The locale used to access filter results for internationalization purposes.
 * @returns {Query<Booking, DocumentData>} A Firestore query object ready to be executed to fetch bookings.
 */

export function buildQuery({
  statusFilter,
  facilityId,
  locale,
}: BuilderQueryProps): Query<Booking, DocumentData> {
  let firebaseQuery = query(
    collection(FirebaseService.firestore, 'bookings'),
    where('facilityId', '==', facilityId),
  ).withConverter(bookingFirestoreConverter);

  switch (statusFilter) {
    case FILTER_RESULTS[locale][0]:
      firebaseQuery = query(
        firebaseQuery,
        where('startTime', '>', +new Date()),
        where('status', '!=', 'cancelled'),
        orderBy('startTime', 'asc'),
      );
      break;
    case FILTER_RESULTS[locale][1]:
      firebaseQuery = query(
        firebaseQuery,
        where('startTime', '<', +new Date()),
        where('status', '!=', 'cancelled'),
        orderBy('startTime', 'desc'),
      );
      break;
    case FILTER_RESULTS[locale][2]:
      firebaseQuery = query(
        firebaseQuery,
        where('status', '==', 'cancelled'),
        orderBy('startTime', 'desc'),
      );
      break;
    case FILTER_RESULTS[locale][3]:
      firebaseQuery = query(
        firebaseQuery,
        where('isFirstRepeat', '==', true),
        orderBy('startTime', 'asc'),
      );
      break;
  }

  return firebaseQuery;
}

/**
 * Filters and groups repeated bookings from a list of bookings.
 * A booking is considered repeated if its `isRepeat` property is true.
 * The bookings are grouped by their `repeatId`.
 * Only groups that contain at least one booking with a status other than 'cancelled' are considered.
 * From these groups, only the bookings that have `isFirstRepeat` true and `isRepeatCancelled` false are returned.
 *
 * @param {Booking[]} bookings - An array of Booking objects to filter and group.
 * @returns {Booking[]} An array of Booking objects that are the first repeat and not cancelled in their group.
 */
export function getRepeatedBookings(bookings: Booking[]): Booking[] {
  const groupedBookings: Record<string, Booking[]> = bookings
    .filter((booking) => booking.isRepeat)
    .reduce((groups, booking) => {
      const key = booking.repeatId;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(booking);
      return groups;
    }, {});

  const allRepeatedBookings = Object.values(groupedBookings).filter(
    (group: Booking[]) =>
      group.some((booking) => booking.status !== 'cancelled'),
  );

  const repeatedBookings: Booking[] = Object.values(allRepeatedBookings)
    .flatMap((bookings) => bookings)
    .filter(
      (booking: Booking) => booking.isFirstRepeat && !booking.isRepeatCancelled,
    );

  return repeatedBookings;
}

import { css } from '@emotion/react';

const monthSwitcher = css`
  display: flex;
  gap: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const currentMonth = css`
  color: #ffffff;
  cursor: pointer;
  border-bottom: 1px solid #ffffff;
`;

const pastMonth = css`
  color: #737373;
  cursor: pointer;
`;

const reservationsGraphHeader = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
`;

const reservationNumber = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #a7a7a7;
`;

const reservationGraphSwitcher = css`
  position: relative;
`;

export const styles = {
  monthSwitcher,
  reservationsGraphHeader,
  reservationNumber,
  reservationGraphSwitcher,
  currentMonth,
  pastMonth,
};

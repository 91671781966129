import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Arrow from 'public/icons/arrow-right.svg';
import React from 'react';

interface SidebarToggleProps {
  isOpen: boolean;
  onClick: () => void;
}

export const SidebarToggle: React.FC<SidebarToggleProps> = ({
  isOpen,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'relative min-w-32 min-h-32 text-small text-white font-primary bg-background-3 rounded-md',
        !isOpen && 'mx-auto',
      )}
    >
      <AnimatePresence mode="wait">
        {isOpen ? (
          <motion.div
            layoutId="toggle-line"
            className="absolute top-4 right-4 w-12 h-24 bg-background-1 rounded-[4px] z-[1]"
          />
        ) : (
          <motion.div
            layoutId="toggle-line"
            className="absolute top-4 left-4 w-12 h-24 bg-background-1 rounded-[4px] z-[1]"
          />
        )}
      </AnimatePresence>
      <div className="absolute right-2 top-[10px]">
        <Arrow
          viewBox="0 0 16 16"
          className="w-14 h-16 [&>path]:fill-background-1 "
        />
      </div>
    </button>
  );
};

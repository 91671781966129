import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const adminMain = css`
  min-height: 100vh;
  ${grid.basic12col};
  grid-column-gap: 0px;
  grid-auto-rows: auto;
  width: 100%;

  @media ${breakpoints.from1024} {
    grid-column-gap: 40px;
  }
`;

const bookingHistoryMain = css`
  grid-column: 1 / 11;
  max-width: 600px;

  @media ${breakpoints.from1024} {
    grid-column: 1 / 7;
  }
`;

export const styles = {
  adminMain,
  bookingHistoryMain,
};

import {
  useGroupAnalyticsListener,
  useSportAnalyticsListener,
} from '@modules/analytics';
import {
  useFacilityRolesListener,
  usePlayoffFacilities,
  usePlayoffFacilityListener,
} from '@modules/facility';
import { useGroupsListener } from '@modules/groups';
import { usePlayoffAdminRedirects } from '@modules/users';
import { LoadingPage } from '@ui/LoadingPage';

interface Props {
  children?: React.ReactNode;
}

export const PlayOffAdminLayout: React.FC<Props> = ({ children }) => {
  /**Facilities collection */
  usePlayoffFacilities();
  usePlayoffFacilityListener();
  useFacilityRolesListener();

  /**Groups collection */
  useGroupsListener();

  /** Analytics collection */
  useSportAnalyticsListener();
  useGroupAnalyticsListener();

  const { loading } = usePlayoffAdminRedirects();

  if (loading) {
    return <LoadingPage />;
  }

  return <>{children}</>;
};

import { SerializedStyles } from '@emotion/react';
import {
  convertPrice,
  useBookingSlotInfo,
  useDeleteBooking,
  useResetBookingSlotInfo,
  useSetBookingSlotInfo,
} from '@modules/booking-new';
import { useCurrentLocale } from '@modules/translations';
import {
  useClickOutsideElement,
  useCloseOnEscape,
  useNoScroll,
} from '@shared/hooks';
import { Button } from '@ui/Button';
import { animations } from 'const';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { BookingDisplay } from '../booking-display';
import { styles } from './bookingSlotInfo.styles';

const Close = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/close.svg'),
  {
    loading: () => null,
  },
);

const Email = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

const User = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/user-16.svg'),
  {
    loading: () => null,
  },
);

export const BookingSlotInfo: FC = () => {
  useNoScroll(true);

  const info = useBookingSlotInfo();
  const setInfo = useSetBookingSlotInfo();
  const ref = useCloseOnEscape<HTMLButtonElement>();
  const outsideClickRef = useClickOutsideElement<HTMLDivElement>(
    () => setInfo(null),
    true,
  );
  const resetInfo = useResetBookingSlotInfo();
  const path = usePathname();
  const { formatMessage } = useIntl();
  const { getLocale } = useCurrentLocale();
  const { deleteSingleBooking } = useDeleteBooking();

  useEffect(() => {
    return () => {
      resetInfo();
    };
  }, [path]);

  if (!info) return null;

  return (
    <motion.div {...animations.opacity({})} css={styles.base}>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        css={styles.modal}
        ref={outsideClickRef}
      >
        <div css={styles.title}>
          <p>{formatMessage({ id: 'booking-slot-info.title' })}</p>
          <Button ref={ref} onClick={() => setInfo(null)}>
            <Close viewBox="0 0 24 24" />
          </Button>
        </div>
        {info?.selectedPackagedBookingData && (
          <div css={styles.paintballContainer}>
            <div css={styles.packageDescriptionContainer}>
              <p css={styles.packageTitle}>
                {info?.selectedPackagedBookingData?.package?.name || ''}
              </p>
              <div css={styles.paintballHoursWrapper}>
                {`€${convertPrice(
                  info?.selectedPackagedBookingData?.package?.price,
                )}`}{' '}
                /{' '}
                {info?.selectedPackagedBookingData?.package?.type ===
                'perInterval'
                  ? formatMessage({ id: 'booking-slot-info.perInterval' })
                  : formatMessage({ id: 'booking-slot-info.perPerson' })}{' '}
                {info?.selectedPackagedBookingData?.package?.interval *
                  info?.selectedPackagedBookingData?.package
                    ?.minSlotsInBooking}{' '}
                {formatMessage({ id: 'booking-slot-info.minutes' })}
              </div>
              <span css={styles.paintballHoursWrapper}>
                {info?.selectedPackagedBookingData?.package?.description}
              </span>
            </div>
            <div css={styles.numberOfPlayersContainer}>
              <User viewBox="0 0 16 16" />
              <span>{info?.selectedPackagedBookingData?.numberOfUsers}</span>
            </div>
          </div>
        )}
        <div css={styles.infoContainer}>
          <p css={styles.date}>
            {format(new Date(info.startTime), 'iii, d. MMM', {
              locale: getLocale(),
            })}
          </p>
          <div css={styles.hoursWrapper}>
            <div>
              {format(info.startTime, 'HH:mm')} -{' '}
              {format(info.endTime, 'HH:mm')}
            </div>
            <span>€{convertPrice(info.price)}</span>
          </div>
          {info?.paymentId && (
            <>
              <BookingDisplay bookingInfo={info} />
              <span css={[styles.itemSecondary, styles.paymentNotice]}>
                {formatMessage({ id: 'booking-slot-info.payment.notice' })}
              </span>
            </>
          )}

          {info.courtName && <div css={styles.court}>{info.courtName}</div>}
        </div>
        <ul css={styles.list}>
          {info.name && (
            <li>
              <span css={styles.itemTitle}>
                {formatMessage({ id: 'booking-slot-info.name' })}
              </span>
              <span css={styles.itemPrimary}>{info.name}</span>
            </li>
          )}

          <li>
            <span css={styles.itemTitle}>
              {formatMessage({ id: 'booking-slot-info.payment' })}
            </span>
            <span css={styles.itemPrimary}>
              {info?.paymentId
                ? formatMessage({ id: 'booking-slot-info.payment.card' })
                : formatMessage({ id: 'booking-slot-info.payment.cash' })}
            </span>
          </li>

          {info.email && (
            <li>
              <span css={styles.itemTitle}>
                {formatMessage({ id: 'booking-slot-info.email' })}
              </span>
              <span css={styles.itemSecondary}>
                {' '}
                <Email viewBox="0 0 16 16" /> {info.email}
              </span>
            </li>
          )}
          {info.phoneNumber && (
            <li>
              <span css={styles.itemTitle}>
                {formatMessage({ id: 'booking-slot-info.phone' })}
              </span>
              <span css={styles.itemPrimary}>{info.phoneNumber}</span>
            </li>
          )}
          {info?.comment && (
            <li>
              <span css={styles.itemTitle}>
                {formatMessage({ id: 'booking-slot-info.comment' })}
              </span>
              <span css={styles.comment}>{info.comment}</span>
            </li>
          )}
          {info.opponent.name && (
            <li>
              <span css={styles.itemTitle}>
                {formatMessage({ id: 'booking-slot-info.secondPlayer' })}
              </span>
              <span css={styles.itemPrimary}>{info.opponent.name}</span>
              {info.opponent.email && (
                <span css={styles.itemSecondary}>
                  <Email viewBox="0 0 16 16" /> {info.opponent.email}
                </span>
              )}
              {info.opponent.phoneNumber && (
                <span css={styles.itemSecondary}>
                  {info.opponent.phoneNumber}
                </span>
              )}
            </li>
          )}
          {info?.createdAt && (
            <li>
              <span css={styles.itemTitle}>
                {formatMessage({ id: 'booking-slot-info.date' })}
              </span>
              <span css={styles.itemPrimary}>
                {format(info.createdAt, 'iii, d. MMM', {
                  locale: getLocale(),
                })}
              </span>
            </li>
          )}
        </ul>
        <div css={styles.buttonWrapper}>
          <Button
            customStyles={styles.buttonBack}
            onClick={() => {
              setInfo(null);
            }}
          >
            {formatMessage({ id: 'booking-slot-info.back' })}
          </Button>
          <Button
            customStyles={styles.buttonCancel}
            onClick={() => {
              deleteSingleBooking(info.id);
              setInfo(null);
            }}
          >
            {formatMessage({ id: 'booking-slot-info.cancel' })}
          </Button>
        </div>
      </motion.div>
    </motion.div>
  );
};

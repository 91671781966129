import { useDefaultClaim } from '@modules/auth';
import { useFacility, useFacilityRoles } from '@modules/facility';
import { getItemRoleLabel } from '@modules/users';
import { useModal } from '@shared/hooks';
import { DateService } from '@shared/services';
import { NewButton } from '@ui/Button';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DeleteRoleModal } from '../delete-role-modal';
import { EditUserModal } from '../edit-user-modal';
import { styles } from './styles';

const IGNORED_EMAILS = [
  'borasmarko07@gmail.com',
  'boris15993@gmail.com',
  'davorstajcertehnicka@gmail.com',
  'dzojicsjepan1812@gmail.com',
  'ivanat735@gmail.com',
  'mark.sost@gmail.com',
  'mkoli2@gmail.com',
  'sandro.blavicki@gmail.com',
  'le.moser96@gmail.com',
  'testerivica@gmail.com',
];

export const UsersManagementTable: React.FC = () => {
  const facilityRoles = useFacilityRoles();
  const defaultClaim = useDefaultClaim();

  const filteredFacilityRoles = useMemo(() => {
    if (defaultClaim?.role === 'playoff-admin') {
      return facilityRoles;
    }
    return facilityRoles?.filter(
      (item) => !IGNORED_EMAILS.includes(item.email),
    );
  }, [facilityRoles, defaultClaim]);

  const {
    closeModal: closeDeleteRoleModal,
    isModalOpen: isDeleteRoleModalOpen,
    openModal: openDeleteRoleModal,
  } = useModal();
  const {
    closeModal: closeEditModal,
    isModalOpen: isEditModalOpen,
    openModal: openEditModal,
  } = useModal();

  const currentFacility = useFacility();
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [role, setRole] = useState<Role | null>(null);

  async function handleDeleteModal(email: string, role: Role) {
    if (!currentFacility?.id) return;
    setEmail(email);
    setRole(role);
    openDeleteRoleModal();
  }

  async function handleEditModal(email: string, displayName: string) {
    if (!currentFacility?.id) return;
    setEmail(email);
    setDisplayName(displayName);
    openEditModal();
  }

  return (
    <div css={styles.containerStyles}>
      <table css={styles.tableStyles}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="users-management-table.email" />
            </th>
            <th>
              <FormattedMessage id="users-management-table.displayName" />
            </th>
            <th>
              <FormattedMessage id="users-management-table.role" />
            </th>
            <th>
              <FormattedMessage id="users-management-table.date" />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {filteredFacilityRoles?.map((item) => (
            <tr key={item.id}>
              <td>{item.email}</td>
              <td>{item.displayName}</td>
              <td>
                <p>{getItemRoleLabel(item.role)}</p>
              </td>
              <td>{DateService.getFormattedDateAndTime(item?.dateCreated)}</td>
              <td className="flex gap-16">
                <NewButton
                  type="button"
                  variant="outline"
                  size="small"
                  onClick={() => handleDeleteModal(item.email, item.role)}
                >
                  <FormattedMessage id="users-management-table.delete-role" />
                </NewButton>
                <NewButton
                  type="button"
                  variant="outline"
                  size="small"
                  onClick={() => handleEditModal(item.email, item.displayName)}
                >
                  <FormattedMessage id="users-management-table.edit-role" />
                </NewButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isDeleteRoleModalOpen && email && role && (
        <DeleteRoleModal
          email={email}
          role={role}
          closeModal={closeDeleteRoleModal}
        />
      )}

      {isEditModalOpen && (
        <EditUserModal
          email={email}
          defaultDisplayNameValue={displayName}
          closeModal={closeEditModal}
        />
      )}
    </div>
  );
};

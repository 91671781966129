import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { breakpoints } from '@styles/variables.styles';

const container = css`
  margin-bottom: 160px;
  display: flex;

  @media ${breakpoints.fromMed} {
    margin-bottom: 20px;
    max-height: 36px;
  }
`;

const inputGroup = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const searchGroup = css`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100%;
  height: 40px;
  margin-bottom: 20px;

  @media ${breakpoints.fromMed} {
    max-height: 40px;
  }
`;

const recentResultsTitle = css`
  font-size: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #9d9daf;
  margin-bottom: 16px;
`;

const input = css`
  @media ${breakpoints.fromMed} {
    display: block;
  }
`;

const desktopSearchButton = css`
  ${button.primary};
  gap: 16px;
  font-weight: 400;
  height: 100%;
  min-width: 64px;
  background-color: var(--color-background-4);
  color: var(--color-text-white);
  border-radius: 44px;
  border-width: 0;
  width: 20%;

  padding: 10px;
  background: #acef34;
  border-radius: 12px;
  display: none;

  @media ${breakpoints.fromMed} {
    padding: 8px 16px;
    display: block;
  }
`;

const mobileSearchButton = css`
  ${desktopSearchButton};
  display: block;
  width: 100%;

  @media ${breakpoints.fromMed} {
    display: none;
  }
`;

export const styles = {
  container,
  desktopSearchButton,
  inputGroup,
  input,
  mobileSearchButton,
  searchGroup,
  recentResultsTitle,
};

import { useState } from 'react';

const oneWeeInkMs = 7 * 24 * 60 * 60 * 1000;

type ExpirationColor = 'Red' | 'Orange' | 'Green';

export function useGroupHelpers() {
  const [date, setDate] = useState(new Date());

  function getLastDateAttended(dates: number[]) {
    if (dates.length === 0) return;

    return dates.reduce((a, b) => (a > b ? a : b), 0);
  }

  function getMemberShipFeeColor(
    info: MembershipInfo[],
    expiryDate: number | null,
  ) {
    if (info.length === 0) return;

    const currentDay = new Date().getTime();
    const lastPaidFee = info.reduce(
      (a, b) => (a.datePaid > b.datePaid ? a : b),
      info[0],
    );
    const oneWeekSpan = expiryDate - oneWeeInkMs;
    let color: ExpirationColor | undefined = undefined;
    const isPayDay =
      new Date(lastPaidFee.datePaid).getFullYear() ===
        new Date(currentDay).getFullYear() &&
      new Date(lastPaidFee.datePaid).getMonth() ===
        new Date(currentDay).getMonth() &&
      new Date(lastPaidFee.datePaid).getDate() ===
        new Date(currentDay).getDate();

    if (isPayDay) color = 'Green';

    if (currentDay >= oneWeekSpan && currentDay <= expiryDate) color = 'Orange';

    if (expiryDate < currentDay) color = 'Red';

    return color;
  }

  function getLastMembershipFee(info: MembershipInfo[]) {
    const monthBeforeData = info[info.length - 2];

    if (info.length === 1 || !monthBeforeData || !monthBeforeData?.expiryDate) {
      return;
    }

    return monthBeforeData.expiryDate;
  }

  function getNewExpirationDate(
    info: MembershipInfo[],
    expiryDate: number | null,
    membershipDuration: number,
  ) {
    if (info.length === 0 || expiryDate === null) {
      const newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + membershipDuration);
      return newDate.getTime();
    }

    let dayToUse =
      Date.now() > expiryDate || date.getTime() > expiryDate
        ? new Date(date)
        : new Date(expiryDate);
    dayToUse.setMonth(dayToUse.getMonth() + membershipDuration);

    return dayToUse.getTime();
  }

  return {
    getLastDateAttended,
    getMemberShipFeeColor,
    getLastMembershipFee,
    getNewExpirationDate,
    date,
    setDate,
  };
}

import {
  Facility,
  useFacilities,
  useSetCurrentFacilityId,
  useSetFacilities,
} from '@modules/facility';
import { useFacilityDocument } from '@modules/facility-management';
import { useLocalStorage } from '@shared/hooks';
import { GeolocationService } from '@shared/services';
import { Modal } from '@ui/Modal';
import { LAST_SELECTED_FACILITY_ID } from 'const';
import { format, setHours, setMinutes } from 'date-fns';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'sonner';

interface Props {
  closeModal: () => void;
}

export const AddNewFacilityModal: React.FC<Props> = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { pathname } = useRouter();
  const facilities = useFacilities();
  const { formatMessage } = useIntl();
  const { createFacility } = useFacilityDocument();
  const setFacilities = useSetFacilities();
  const setCurrentFacilityId = useSetCurrentFacilityId();
  const [, setLastSelectedVisible] = useLocalStorage<string>(
    LAST_SELECTED_FACILITY_ID,
  );

  async function handleSubmit() {
    try {
      setLoading(true);
      const currentDate = format(new Date(), 'dd/MM/yyyy');

      const street = 'Ulica Kralja Petra Svačića 1c';
      const city = 'Osijek';
      const country = 'Croatia';
      const zip = '31000';
      const fullAddress = `${street}, ${city}, ${zip}, ${country}`;

      let latitude = 0;
      let longitude = 0;

      try {
        const geoResult = await GeolocationService.geocodeAddress(fullAddress);
        latitude = geoResult.latitude;
        longitude = geoResult.longitude;
      } catch (geoError) {
        console.error('Failed to get coordinates:', geoError);
      }

      const today = new Date();
      const startTime = setHours(setMinutes(new Date(today), 0), 8);
      const endTime = setHours(setMinutes(new Date(today), 0), 16);
      const startTimestamp = startTime.getTime();
      const endTimestamp = endTime.getTime();

      const defaultWorkSchedule = {
        ranges: [
          {
            from: { day: 1, month: 1 },
            to: { day: 31, month: 12 },
          },
        ],
        workingHours: {
          Monday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
          Tuesday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
          Wednesday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
          Thursday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
          Friday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
          Saturday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
          Sunday: [{ from: startTimestamp, to: endTimestamp, price: 25 }],
        },
      };

      const newFacilityData = new Facility({
        name: `#${facilities.length + 1} Facility ${currentDate}`,
        city,
        street,
        zip,
        country,
        contactEmail: '',
        phone: [''],
        type: 'sport',
        courts: [
          {
            isSmartLockAvailable: false,
            id: 'teren1',
            name: 'Teren 1',
            timeRanges: [
              {
                startTime: startTimestamp,
                endTime: endTimestamp,
              },
            ],
          },
        ],
        blockedUsers: [],
        description: [``],
        englishDescription: [],
        discoverCity: 'Osijek',
        languageCode: 'en',
        latitude,
        longitude,
        interval: 30,
        isCashBookingOutsideCityEnabled: false,
        isPrivate: false,
        isLive: false,
        logo: '',
        images: [],
        discoverPriority: 'low',
        availablePaymentMethods: ['cash'],
        sports: ['football'],
        amenities: ['parking', 'wifi'],
        web: null,
        stripeAccountId: null,
        baseSlotHeightInMinutes: null,
        maxAllowedFutureReservationDays: null,
        maxSlotsInBooking: null,
        minSlotsInBooking: null,
        paymentPercentage: null,
        createdAt: Date.now(),
        isPhoneRequired: null,
        isTeamGameSupported: false,
        bookingLimits: null,
        packagedBookingData: null,
        stripeCancellationConfigs: null,
        bookingAddOns: null,
        lmsLeagueConfig: null,
        discountConfig: null,
        temporaryClosureConfig: null,
        workDays: {
          nonWorkingDays: [],
          workingHours: {
            Monday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Tuesday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Wednesday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Thursday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Friday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Saturday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Sunday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
          },
          adminWorkingHours: {
            Monday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Tuesday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Wednesday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Thursday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Friday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Saturday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
            Sunday: [{ from: startTimestamp, to: endTimestamp, price: 0 }],
          },
          differentWorkingHours: [],
          workSchedules: [defaultWorkSchedule],
        },
      });

      const facilityId = await createFacility(newFacilityData);
      setFacilities([
        ...facilities,
        {
          ...newFacilityData,
          id: facilityId,
        },
      ]);
      setCurrentFacilityId(facilityId);
      setLastSelectedVisible(facilityId);
      if (pathname.includes('facility-management')) {
        router.push(`/dashboard/playoff-admin/booking-calendar`);
      } else {
        router.push(`/dashboard/playoff-admin/facility-management`);
      }
      toast.success(formatMessage({ id: 'add-new-facility.toasts.success' }));
      closeModal();
    } catch (error) {
      toast.error(formatMessage({ id: 'add-new-facility.toasts.error' }));
      console.error('Error creating new facility:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      onClose={closeModal}
      title={formatMessage({ id: 'add-new-facility.title' })}
      handleSubmit={handleSubmit}
      confirmText={formatMessage({ id: 'input.add' })}
      cancelText={formatMessage({ id: 'input.cancel' })}
      isLoading={loading}
    >
      <p className="text-white text-base-fluid">
        <FormattedMessage id="add-new-facility.description" />
      </p>
    </Modal>
  );
};

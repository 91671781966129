import { useDefaultClaim, useUserClaims } from '@modules/auth';
import { useSetCurrentFacilityId } from '@modules/facility';
import {
  useClickOutsideElement,
  useCloseOnEscape,
  useLocalStorage,
} from '@shared/hooks';
import { LAST_SELECTED_FACILITY_ID } from 'const';
import { useRouter } from 'next/router';
import { useState } from 'react';

export function useAdminDashboardNavDropdown() {
  const router = useRouter();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [, setLastSelectedVisible] = useLocalStorage<string>(
    LAST_SELECTED_FACILITY_ID,
  );

  const claims = useUserClaims();
  const defaultClaim = useDefaultClaim();
  const setCurrentFacilityId = useSetCurrentFacilityId();

  const ref = useCloseOnEscape<HTMLDivElement>();
  const dropdownRef = useClickOutsideElement<HTMLDivElement>(
    () => setIsDropdownVisible(false),
    true,
  );

  function handleFacilityChange(facilityId: string, type: SportFacility) {
    setCurrentFacilityId(facilityId);
    setLastSelectedVisible(facilityId);
    setIsDropdownVisible(false);
    const newRole = claims?.find(
      (claim) => claim?.facilityId === facilityId,
    )?.role;

    if (type === 'group') {
      router.push(`/dashboard/${newRole || defaultClaim?.role}/groups`);
      return;
    }

    router.push(`/dashboard/${newRole || defaultClaim?.role}/booking-calendar`);
  }

  function handleContainerClick() {
    setIsDropdownVisible(!isDropdownVisible);
  }

  function isActive(facilityName: string, currentFacilityValue: string) {
    return currentFacilityValue === facilityName;
  }

  return {
    dropdownRef,
    ref,
    isDropdownVisible,
    isActive,
    handleContainerClick,
    handleFacilityChange,
  };
}

import { css } from '@emotion/react';
import CreditCard from 'public/icons/credit-card.svg';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface BookingDisplayProps {
  bookingInfo: Booking;
}

export const BookingDisplay: FC<BookingDisplayProps> = ({ bookingInfo }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {' '}
      {bookingInfo.paymentId && (
        <div css={styles.paymentBadge}>
          <div css={styles.paymentIcon}>
            <CreditCard />
          </div>
          {formatMessage({ id: 'booking-display.paid-online' })}
        </div>
      )}{' '}
    </>
  );
};

const styles = {
  paymentBadge: css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 16px;
    margin-top: 8px;
    background-color: #56f2df0d;
    border-radius: 12px;
    color: #acef34;
    width: fit-content;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
  `,
  paymentIcon: css`
    display: flex;
    align-items: center;
  `,
};

import { css } from '@emotion/react';
import { display } from '@styles/display.styles';
import { breakpoints } from '@styles/variables.styles';

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16141bcc;
  overflow: hidden;
  z-index: 3;
`;

const base = css`
  display: flex;
  border: 1px solid #f8f8f814;
  border-radius: 8px;
  overflow: hidden;
`;

const columnOne = css`
  ${display.overflowAuto};
  height: 100%;
  padding: 36px 32px;
  width: 100%;
  max-width: 500px;
  background-color: var(--color-background-2);

  @media ${breakpoints.fromMed} {
    min-width: 420px;
  }
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #ffffff;
`;

const description = css`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

const confirmDescription = css`
  margin: 16px 0;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

export const styles = {
  base,
  wrapper,
  columnOne,
  title,
  description,
  confirmDescription,
};

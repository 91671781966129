import { css } from '@emotion/react';

const selectWrapper = css`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  margin: 24px 0;
  padding-bottom: 12px;
  overflow-x: auto;
`;

const bookingStatusList = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const bookingStatusPicker = css`
  box-sizing: border-box;
  background: var(--color-background-4);
  border-radius: 12px;
  margin-right: 8px;
  padding: 12px 20px;
  cursor: pointer;
  min-height: 46px;
  transition: all 0.15s ease-out;
`;

const active = css`
  border: 1px solid var(--color-border-2);
  background: var(--color-background-5);
  color: var(--color-general-primary);
  transition: all 0.15s ease-out;
`;

export const styles = {
  selectWrapper,
  bookingStatusList,
  bookingStatusPicker,
  active,
};

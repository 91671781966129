import { css } from '@emotion/react';
import { scroll } from '@styles/scroll.styles';
import { breakpoints } from '@styles/variables.styles';

export const base = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #16141bcc;
  z-index: 3;
`;

const modal = css`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background-color: var(--color-background-2);
  border-radius: 8px;
  max-height: 90%;

  @media ${breakpoints.fromSml} {
    width: 480px;
    padding: 36px 32px;
  }
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text-6);
`;

const item = css`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border: 1px solid rgba(248, 248, 248, 0.08);
  border-radius: 10px;
  color: var(--color-text-5);
`;

const buttonConfirm = css`
  background-color: var(--color-error);
  color: var(--color-text-6);
  padding: 8px 28px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

const buttonBack = css`
  padding: 8px 28px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  background-color: transparent;
  color: var(--color-text-5);
`;

const buttonWrapper = css`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
`;

const name = css`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const court = css`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
`;

const date = css`
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;

  span {
    font-size: 16px;
    color: var(--color-text-6);
    font-weight: 700;
    margin-left: 12px;
  }
`;

const list = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${scroll.base}
`;

const linkText = css`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-5);

  a {
    color: var(--color-general-primary);
    text-decoration: underline;
  }
`;

export const styles = {
  base,
  modal,
  title,
  item,
  buttonConfirm,
  buttonBack,
  buttonWrapper,
  name,
  court,
  date,
  list,
  linkText,
};

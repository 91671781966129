import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const nav = css`
  background-color: var(--color-background-1);
  position: fixed;
  left: 20px;
  top: 0;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-left: -20px;
  z-index: 3;
  padding: 0 10px;
  border-right: 1px solid #505050;
  overflow-x: hidden;
`;

const link = css`
  display: flex;
  position: relative;
  align-items: center;
  gap: 12px;
  transition: all 0.15s ease-out;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  width: 100%;
  color: var(--color-text-5);
  transition: color 0.15s ease-out;
  padding: 8px 12px;

  path {
    transition: all 0.15s ease-out;
  }

  &:hover,
  &:focus {
    color: var(--color-general-primary);

    & path {
      fill: var(--color-general-primary);
    }
  }

  &:hover {
    filter: gray saturate(0%) brightness(70%) contrast(1000%);
  }
`;

const linkOpen = css`
  ${link}
  max-width: 100%;
`;

const icons = css`
  margin-right: 0;
  vertical-align: middle;
  min-width: max-content;

  @media ${breakpoints.from1024} {
    margin-right: 12px;
  }
`;

const disabled = css`
  pointer-events: none;
  opacity: 0.5;
`;

const sectionWrapper = css`
  margin-top: 12px;
`;

const sectionTitle = css`
  margin-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-3);
  margin: 32px 12px 16px;
`;

const logo = css`
  display: block;
  cursor: pointer;
  margin-bottom: 40px;
  width: 100%;
  margin: 0 auto;

  svg {
    max-width: 68px;
    width: 100%;
    height: auto;
  }
`;

const list = css`
  display: flex;
  flex-direction: column;
  position: relative;
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
  gap: 4px;
  width: 100%;
`;

const activeLink = css`
  color: var(--color-general-primary);
  background-color: #211f26;

  & path {
    margin-right: 12px;
    fill: var(--color-general-primary);
  }
`;

const logout = css`
  display: none;

  @media ${breakpoints.fromMed} {
    display: inline-block;
    margin-top: 60px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #737373;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }
`;

const logoSecondary = css`
  display: block;
  cursor: pointer;
  width: 46.5px;

  path {
    fill: var(--color-text-3);
    transition: all 0.15s ease-out;
  }
`;

const accordionLogo = css`
  position: relative;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 0 40px;
  width: 100%;
`;

const accordionLogoOpen = css`
  ${accordionLogo}
  gap: 12px;
`;

const logoWrapper = css`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--color-text-4);
  padding: 4px 0;
  min-width: max-content;

  span {
    transition: all 0.15s ease-out;
  }

  :hover {
    span {
      color: var(--color-text-5);
    }

    path {
      fill: var(--color-text-5);
    }
  }
`;

const footer = css`
  font-size: 12px;
  line-height: 16px;
  margin-top: auto;
  color: var(--color-text-3);
  display: flex;
  flex-direction: column;

  a {
    transition: color 0.15s ease-out;
    padding: 4px 0;

    :hover {
      color: var(--color-text-5);
    }
  }
`;

const addFacility = css`
  align-self: center;
  margin-top: 16px;
`;

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 2;
`;

const footerLinks = css`
  display: flex;
  flex-direction: column;

  a {
    min-width: max-content;
  }
`;

const horizontalLine = css`
  grid-column: 1 / span 30;
  width: 100%;
  border-color: rgba(248, 248, 248, 0.08);
  margin: 32px 0;
`;

export const styles = {
  nav,
  overlay,
  link,
  linkOpen,
  icons,
  sectionWrapper,
  sectionTitle,
  disabled,
  logo,
  list,
  activeLink,
  logout,
  logoSecondary,
  footer,
  logoWrapper,
  addFacility,
  horizontalLine,
  accordionLogo,
  accordionLogoOpen,
  footerLinks,
};

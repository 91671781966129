import { useLocalStorage } from '@shared/hooks';
import { LAST_SELECTED_FACILITY_ID } from 'const';
import { FirebaseError } from 'firebase/app';
import { updateProfile } from 'firebase/auth';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import { FirebaseAuthService } from '../service';
import { getLoginErrorMessage, getRegisterErrorMessage } from '../utils';
import { useResetPassword } from './useResetPassword';

export function useFirebaseAuth() {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const [, setLastSelectedFacilityId] = useLocalStorage<string>(
    LAST_SELECTED_FACILITY_ID,
  );
  const [, setIsDemoModalHidden] =
    useLocalStorage<boolean>('isDemoModalHidden');
  const AuthService = new FirebaseAuthService();
  const { sendForgotPasswordEmail } = useResetPassword();

  async function handleLogin(email: string, password: string) {
    try {
      const toastMessage = formatMessage({
        id: 'auth-toast.success.login',
      });
      await AuthService.login(email, password);
      toast.success(toastMessage, { id: 'login' });
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = formatMessage({
          id: getLoginErrorMessage(errorCode),
        });
        toast.error(errorMessage);
      }
    }
  }

  async function handleDemoLogin() {
    try {
      setLoading(true);
      await AuthService.login(
        process.env.NEXT_PUBLIC_DEMO_ACCOUNT_EMAIL || '',
        process.env.NEXT_PUBLIC_DEMO_ACCOUNT_PASSWORD || '',
      );
      setIsDemoModalHidden(false);
      toast.info(
        formatMessage({
          id: 'auth-toast.success.demo',
        }),
        {
          duration: 6000,
        },
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = formatMessage({
          id: getLoginErrorMessage(errorCode),
        });
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleLogout() {
    try {
      const toastMessage = formatMessage({
        id: 'auth-toast.success.logout',
      });
      await AuthService.logout();
      setLastSelectedFacilityId('');
      setIsDemoModalHidden(null);
      router.push('/login');
      toast.success(toastMessage, { id: 'logout' });
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorMessage = formatMessage({
          id: 'auth-toast.error.logout',
        });
        toast.error(errorMessage);
      }
    }
  }

  async function handleResetPassword(email: string) {
    try {
      const toastMessage = formatMessage({
        id: 'auth-toast.success.reset-password',
      });
      await sendForgotPasswordEmail({ email });
      toast.success(toastMessage, { id: 'reset-password' });
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = formatMessage({
          id: getLoginErrorMessage(errorCode),
        });
        toast.error(errorMessage);
      }
    }
  }

  async function handleSignup(
    email: string,
    password: string,
    displayName?: string,
  ) {
    try {
      const credential = await AuthService.register(email, password);
      await updateProfile(credential.user, { displayName });
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        toast.error(getRegisterErrorMessage(errorCode));
      }
    }
  }

  async function handleGoogleLogin() {
    try {
      await AuthService.loginWithGoogle();
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        toast.error(getLoginErrorMessage(errorCode));
      }
    }
  }

  async function handleFacebookLogin() {
    try {
      await AuthService.loginWithFacebook();
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        toast.error(getLoginErrorMessage(errorCode));
      }
    }
  }

  async function deleteAccount() {
    try {
      await AuthService.deleteAccount();
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error instanceof FirebaseError) toast.error(error.message);
      }
    }
  }

  return {
    loading,
    handleLogin,
    handleGoogleLogin,
    handleFacebookLogin,
    handleLogout,
    handleSignup,
    handleResetPassword,
    handleDemoLogin,
    deleteAccount,
  };
}

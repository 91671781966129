import { useIsDayView, useSetIsDayView } from '@modules/booking-new';
import { Button } from '@ui/Button';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { styles } from './viewPicker.styles';

export const ViewPicker: FC = () => {
  const isDay = useIsDayView();
  const setIsDay = useSetIsDayView();
  const { formatMessage } = useIntl();

  return (
    <div css={styles.wrapper}>
      <span css={styles.display}>
        {formatMessage({ id: 'view-picker.display' })}
      </span>
      <Button customStyles={styles.base} onClick={() => setIsDay(!isDay)}>
        <span css={isDay ? styles.textActive : styles.text}>
          {formatMessage({ id: 'view-picker.day' })}
        </span>
        <span css={!isDay ? styles.textActive : styles.text}>
          {formatMessage({ id: 'view-picker.week' })}
        </span>
        <div css={isDay ? styles.circleDay : styles.circleWeek} />
      </Button>
    </div>
  );
};

import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  isVertical?: boolean;
}

export const AccordionWrapper: FC<Props> = ({
  isOpen,
  children,
  isVertical,
}) => {
  const gridDirectionOpen = isVertical ? 'grid-rows-[1fr]' : 'grid-cols-[1fr]';
  const gridDirectionClosed = isVertical
    ? 'grid-rows-[0fr]'
    : 'grid-cols-[0fr]';

  return (
    <div
      className={clsx(
        'grid transition-all duration-300 ease-out',
        isOpen ? gridDirectionOpen : gridDirectionClosed,
      )}
    >
      <div className="overflow-hidden">
        <span className="block text-base text-gray-8 min-w-max">
          {children}
        </span>
      </div>
    </div>
  );
};

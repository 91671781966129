import { useAdminRedirects } from '@modules/admin';
import {
  useGroupAnalyticsListener,
  useSportAnalyticsListener,
} from '@modules/analytics';
import {
  useFacilitiesListener,
  useFacilityListener,
  useFacilityRolesListener,
} from '@modules/facility';
import { useGroupsListener } from '@modules/groups';
import { LoadingPage } from '@ui/LoadingPage';

interface Props {
  children?: React.ReactNode;
}

export const AdminLayout: React.FC<Props> = ({ children }) => {
  /**Facilities collection */
  useFacilitiesListener();
  useFacilityListener();
  useFacilityRolesListener();

  /**Groups collection */
  useGroupsListener();

  /** Analytics collection */
  useSportAnalyticsListener();
  useGroupAnalyticsListener();

  const { loading } = useAdminRedirects();

  if (loading) {
    return <LoadingPage />;
  }

  return <>{children}</>;
};

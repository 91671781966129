import { SerializedStyles } from '@emotion/react';
import {
  useBookedHoursList,
  useIsActiveSummaryModal,
  useIsDestructiveMode,
} from '@modules/booking-new';
import { Button } from '@ui/Button';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BookedListItems } from '../booked-list-items/BookedListItems';
import { styles } from './bookingWeekSummary.styles';
import { BookingSummaryButtons } from './buttons/BookingSummaryButtons';
import { Repeat } from './repeat/Repeat';

const Minimize = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/minimize.svg'),
  {
    loading: () => null,
  },
);

const Maximize = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/maximize.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  isDayView?: boolean;
}

export const BookingWeekSummary: FC<Props> = ({ isDayView = false }) => {
  const isDestructive = useIsDestructiveMode();
  const isActiveSummaryModal = useIsActiveSummaryModal();
  const { sortedList } = useBookedHoursList();
  const [isMinimized, setIsMinimized] = useState(false);
  const baseStyle = useMemo(
    () =>
      isMinimized
        ? isDestructive
          ? styles.baseMinimizedDestructive
          : styles.baseMinimized
        : styles.base,
    [isMinimized, isDestructive],
  );

  useEffect(() => {
    if (!isActiveSummaryModal) {
      setIsMinimized(false);
    }
  }, [isActiveSummaryModal]);

  return (
    <AnimatePresence mode="wait">
      {isActiveSummaryModal && (
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
          css={[baseStyle, isDayView && styles.baseDayView]}
        >
          <div css={isMinimized ? styles.listWrapperMinimized : ''}>
            <Button
              customStyles={styles.title}
              onClick={() => setIsMinimized(!isMinimized)}
            >
              <p>
                <FormattedMessage id="booking-summary.selected-slots" />
                {` (${sortedList.length})`}
              </p>
              {isMinimized && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: 'easeOut', delay: 0.1 }}
                >
                  <Maximize viewBox="0 0 16 16" />
                </motion.div>
              )}
              {!isMinimized && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: 'easeOut', delay: 0.1 }}
                >
                  <Minimize viewBox="0 0 16 16" />
                </motion.div>
              )}
            </Button>
            <BookedListItems />
          </div>
          <div css={styles.divider}>
            {!isDestructive && <Repeat />}
            <BookingSummaryButtons />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

import { db } from '@modules/firebase/config';
import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface Params {
  facility: Facility;
  court: Court;
  smartLock: SmartLock;
  page?: number;
  itemsPerPage?: number;
}

export const useSmartLockLogs = ({
  facility,
  court,
  smartLock,
  page = 1,
  itemsPerPage = 10,
}: Params) => {
  const [logs, setLogs] = useState<SmartLockLog[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [pageCache, setPageCache] = useState<Record<number, any>>({});

  useEffect(() => {
    if (!smartLock) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    const logsRef = collection(
      db,
      `smartLocks/${facility.id}-${court.id}-${smartLock.name}/logs`,
    );
    const cleanupFunctions: Array<() => void> = [];

    const countListener = onSnapshot(
      logsRef,
      async () => {
        try {
          const countSnapshot = await getCountFromServer(
            query(logsRef, where('type', '!=', 'hartbeat')),
          );
          setTotalCount(countSnapshot.data().count);
        } catch (err) {
          console.error('Count error:', err);
          setError(err as Error);
        }
      },
      (err) => setError(err),
    );

    cleanupFunctions.push(countListener);

    const fetchLogs = async () => {
      const baseConditions = [
        where('type', '!=', 'hartbeat'),
        orderBy('timestamp', 'desc'),
        limit(itemsPerPage),
      ];

      try {
        if (page === 1) {
          const q = query(logsRef, ...baseConditions);
          setupLogsListener(q);
          return;
        }

        if (pageCache[page - 1]) {
          const q = query(
            logsRef,
            ...baseConditions,
            startAfter(pageCache[page - 1]),
          );
          setupLogsListener(q);
          return;
        }

        const previousDocs = await getDocs(
          query(
            logsRef,
            where('type', '!=', 'hartbeat'),
            orderBy('timestamp', 'desc'),
            limit((page - 1) * itemsPerPage),
          ),
        );

        const newCache = { ...pageCache };
        previousDocs.docs.forEach((doc, idx) => {
          const pageNum = Math.floor(idx / itemsPerPage) + 1;
          if (
            (idx + 1) % itemsPerPage === 0 ||
            idx === previousDocs.docs.length - 1
          ) {
            newCache[pageNum] = doc;
          }
        });
        setPageCache(newCache);

        const lastDoc = previousDocs.docs[previousDocs.docs.length - 1];
        const q = query(logsRef, ...baseConditions, startAfter(lastDoc));
        setupLogsListener(q);
      } catch (err) {
        console.error('Logs error:', err);
        setError(err as Error);
        setLoading(false);
      }
    };

    function setupLogsListener(q: any) {
      const logsListener = onSnapshot(
        q,
        (snapshot) => {
          const newLogs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as SmartLockLog[];

          setLogs(newLogs);

          if (snapshot.docs.length > 0) {
            setPageCache((prev) => ({
              ...prev,
              [page]: snapshot.docs[snapshot.docs.length - 1],
            }));
          }

          setLoading(false);
        },
        (err) => {
          console.error('Logs listener error:', err);
          setError(err);
          setLoading(false);
        },
      );

      cleanupFunctions.push(logsListener);
    }

    fetchLogs();

    return () => {
      cleanupFunctions.forEach((fn) => fn());
    };
  }, [facility.id, court.id, smartLock, page, itemsPerPage]);

  return { logs, totalCount, loading, error };
};

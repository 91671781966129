import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Logo from 'public/logo/playoff-logo-68.svg';
import { FormattedMessage } from 'react-intl';
import { styles } from '../styles';

import { useNoScroll } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { Hamburger } from './Hamburger';
import { NavigationHeader } from './NavigationHeader';

interface Props {
  locale: string;
  isLanguageSwitcherModalOpen: boolean;
  isAdmin: boolean;
  isEmployee: boolean;
  openModal: () => void;
  handleAdminClick: () => string;
}

export const LandingHeader: React.FC<Props> = ({
  locale,
  isLanguageSwitcherModalOpen,
  isAdmin,
  isEmployee,
  openModal,
  handleAdminClick,
}) => {
  const pathname = usePathname();
  const [isActive, setIsActive] = useState(false);

  const navStyle = (path: string) => {
    if (pathname === path) return styles.navigationItemActive;

    return styles.navigationItem;
  };

  useNoScroll(isActive);

  useEffect(() => {
    setIsActive(false);
  }, [pathname]);

  return (
    <>
      <div className="max-992:w-full flex justify-center gap-24">
        <div className="max-992:w-full flex justify-between items-center">
          <Link href="/" className="z-20">
            <Logo viewBox="0 0 68 16" css={styles.logo} />
          </Link>
          <Hamburger
            isActive={isActive}
            changeState={() => setIsActive(!isActive)}
            navigation={
              <div className="flex gap-6 md:gap-8 px-16">
                <NavigationHeader
                  locale={locale}
                  isLanguageSwitcherModalOpen={isLanguageSwitcherModalOpen}
                  isAdmin={isAdmin}
                  isEmployee={isEmployee}
                  openModal={openModal}
                  handleAdminClick={handleAdminClick}
                />
                <Link href="/contact" css={styles.partner}>
                  <span>
                    <FormattedMessage id="header.become-partner" />
                  </span>
                </Link>
              </div>
            }
          />
        </div>
        <nav css={styles.navigation}>
          <Link
            css={navStyle('/why-playoff')}
            href="/why-playoff"
            scroll={false}
          >
            <FormattedMessage id="header.why" />
          </Link>
          <Link css={navStyle('/partners')} href="/partners" scroll={false}>
            <FormattedMessage id="header.partners" />
          </Link>
          <Link css={navStyle('/directory')} href="/directory" scroll={false}>
            <FormattedMessage id="header.directory" />
          </Link>
          <Link css={navStyle('/contact')} href="/contact" scroll={false}>
            <FormattedMessage id="header.contact" />
          </Link>
        </nav>
      </div>
      <div className="max-992:hidden flex gap-8">
        <NavigationHeader
          locale={locale}
          isLanguageSwitcherModalOpen={isLanguageSwitcherModalOpen}
          isAdmin={isAdmin}
          isEmployee={isEmployee}
          openModal={openModal}
          handleAdminClick={handleAdminClick}
        />
        <Link href="/contact" css={styles.partner}>
          <span>
            <FormattedMessage id="header.become-partner" />
          </span>
        </Link>
      </div>
    </>
  );
};

import {
  format,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';

interface WeekInCroatianProps {
  duration: number;
  label: {
    first: string;
    second: string;
    third: string;
  };
}

export class DateService {
  /**
   * Converts a timestamp to time string in HH:mm format
   * @param timestamp - Unix timestamp in milliseconds
   * @returns Time in "HH:mm" format
   */
  static timestampToTime(timestamp: number): string {
    if (typeof timestamp !== 'number') return '';
    return format(new Date(timestamp), 'HH:mm');
  }

  /**
   * Converts time string (HH:mm) to a timestamp with today's date
   * @param time - Time in "HH:mm" format or timestamp in milliseconds
   * @returns Unix timestamp in milliseconds or null if no time provided
   */
  static timeToTimestamp(time: string | number): number | null {
    if (!time) return null;
    if (typeof time === 'number') return time;
    const [hours, minutes] = time.split(':').map(Number);
    const date = setMinutes(setHours(new Date(), hours), minutes);
    return setSeconds(setMilliseconds(date, 0), 0).getTime();
  }

  /**
   * Get the time from a timestamp in a specific locale.
   *
   * @param {number} timestamp - The timestamp to convert.
   * @param {string} [locale] - The locale to use for formatting.
   * @returns {string} The formatted time string.
   */
  static getTime(timestamp: number, locale?: string): string {
    const date = new Date(timestamp);

    const time = date.toLocaleString(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: locale === 'hr' ? false : true,
    });

    return `${time}${locale === 'hr' ? 'h' : ''}`;
  }

  /**
   * Get the formatted date and time from a timestamp in a specific locale.
   *
   * @param {number} timestamp - The timestamp to convert.
   * @param {string} [locale] - The locale to use for formatting.
   * @returns {string} The formatted date and time string.
   */
  static getFormattedDateAndTime(date: string): string {
    const dateObject = new Date(date);
    const dateFormat = 'dd.MM.yyyy. HH:mm';
    const formattedDate = format(dateObject, dateFormat);

    return formattedDate;
  }

  /**
   * Get the week label in Croatian based on the duration.
   *
   * @param {WeekInCroatianProps} props - The duration and label object.
   * @returns {string} The appropriate label based on the duration.
   */
  static getWeekInCroatian({ duration, label }: WeekInCroatianProps): string {
    const lastDigit = duration % 10;
    const secondLastDigit = Math.floor((duration % 100) / 10);

    if (lastDigit === 1 && secondLastDigit !== 1) {
      return label.first;
    } else if (lastDigit >= 2 && lastDigit <= 4 && secondLastDigit !== 1) {
      return label.second;
    } else {
      return label.third;
    }
  }

  /**
   * Creates a timestamp for today with specific hours and minutes
   * @param hours - Hours (0-23)
   * @param minutes - Minutes (0-59)
   * @returns Unix timestamp in milliseconds
   */
  static createTimeForToday(hours: number, minutes: number): number {
    const date = setMinutes(setHours(new Date(), hours), minutes);
    return setSeconds(setMilliseconds(date, 0), 0).getTime();
  }

  /**
   * Creates a date object that is one week later from the given date
   * @param date - Base date (defaults to today if not provided)
   * @returns Date object one week later
   */
  static getOneWeekLater(date: Date = new Date()): Date {
    const oneWeekLater = new Date(date);
    oneWeekLater.setDate(date.getDate() + 7);
    return oneWeekLater;
  }
}

import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const baseDayView = css`
  @media ${breakpoints.fromMed} {
    display: none;
  }
`;

const base = css`
  position: fixed;
  z-index: 3;
  bottom: 40px;
  right: 20px;
  box-shadow: 0px 4px 32px 44px rgba(22, 20, 27, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: max-content;
  padding: 20px 16px;
  background-color: var(--color-background-4);
  border-radius: 8px;
  width: 280px;
  border: 1px solid var(--color-border-cardBorder);
  max-height: 90vh;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @media ${breakpoints.fromSml} {
    width: 300px;
  }
`;

const baseMinimized = css`
  ${base};
  overflow: hidden;
  max-height: 180px;
`;

const baseMinimizedDestructive = css`
  ${base};
  overflow: hidden;
  max-height: 160px;
`;

const title = css`
  position: sticky;
  top: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-background-4);
  padding: 0 0 4px;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-out;

  svg {
    path {
      transition: all 0.2s ease-out;
    }
  }

  :hover {
    color: var(--color-text-6);

    svg {
      path {
        stroke: var(--color-text-6);
      }
    }
  }
`;

const bookedHourList = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

const listWrapperMinimized = css`
  overflow: hidden;
`;

const divider = css`
  padding-top: 16px;
`;

const checkboxWrapper = css`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding-top: 20px;
`;

const repeatText = css`
  color: var(--color-text-6);
  font-size: 14px;
  line-height: 16px;
`;

const durationText = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 12px;
  color: var(--color-text-5);
`;

const bookingRepeatDropdown = css`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  position: relative;
`;

const bookingRepeatLabel = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-4);
  background: var(--color-background-4);
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const select = css`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-text6);
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const selectedOption = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-general-primary);
`;

const checkbox = css`
  position: relative;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border: 1px solid var(--color-border-4);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }
`;

const checkboxActive = css`
  ${checkbox};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid var(--color-general-primary);
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const buttonWrapper = css`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding-top: 20px;

  button {
    width: 50%;
    min-width: max-content;
  }
`;

const buttonConfirm = css`
  padding: 8px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;
  font-family: var(--font-family-primarySemiBold);
  color: var(--color-text-1);
  background-color: var(--color-general-primary);
  transition: all 0.2s ease-out;

  :hover {
    background-color: var(--color-general-primary-plus1);
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.4;

    :hover {
      background-color: var(--color-general-primary);
    }
  }
`;

const buttonConfirmDesctructive = css`
  ${buttonConfirm};
  background-color: var(--color-error-utility);
  color: var(--color-text-6);

  :hover {
    background-color: var(--color-error-utility);
  }

  :disabled {
    :hover {
      background-color: var(--color-error-utility);
    }
  }
`;

const cancel = css`
  border: none;
  cursor: pointer;
  color: var(--color-text-5);
  background-color: var(--color-background-5);
  padding: 8px 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;

  :hover {
    background-color: var(--color-background-6);
    border-color: var(--color-border-cardBorder);
  }

  :disabled {
    opacity: 0.4;
    cursor: pointer;
  }
`;

export const styles = {
  base,
  baseMinimized,
  baseDayView,
  title,
  bookedHourList,
  checkboxWrapper,
  repeatText,
  durationText,
  bookingRepeatDropdown,
  bookingRepeatLabel,
  select,
  selectedOption,
  checkbox,
  checkboxActive,
  buttonWrapper,
  buttonConfirm,
  buttonConfirmDesctructive,
  cancel,
  divider,
  listWrapperMinimized,
  baseMinimizedDestructive,
};

import { styles as dateStyles } from '@modules/booking-new/components/date-picker/dateWeekCalendar.styles';
import { useGroupFirebase, useGroupHelpers } from '@modules/groups/hooks';
import { useIsLoading } from '@modules/groups/state';
import { useCurrentLocale } from '@modules/translations';
import { useClickOutsideElement } from '@shared/hooks';
import { Button } from '@ui/Button';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import ArrowIconDown from 'public/icons/arrow-icon-down-16.svg';
import ArrowIconUp from 'public/icons/arrow-icon-up-16.svg';
import Calendar from 'public/icons/calendar-16.svg';
import { FC, useMemo, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { FormattedMessage, useIntl } from 'react-intl';
import { MembershipDurationOptions } from './MembershipDurationOptions';
import { styles } from './membershipExtend.styles';

interface Props {
  groupId: string;
  member: GroupMember;
  setCurrentStep: (value: string) => void;
  setCurrentMembershipStep: (value: string) => void;
}

const MEMBERSHIP_DURATION_KEYS = [
  { key: 'month', value: 1 },
  { key: 'three-months', value: 3 },
  { key: 'six-months', value: 6 },
  { key: 'year', value: 12 },
];

export const MembershipExtend: FC<Props> = ({
  groupId,
  member,
  setCurrentStep,
  setCurrentMembershipStep,
}) => {
  const { formatMessage } = useIntl();
  const isLoading = useIsLoading();
  const translatedMembershipDuration = useMemo(() => {
    return MEMBERSHIP_DURATION_KEYS.map(({ key, value }) => ({
      label: formatMessage({ id: `membership.duration.${key}` }),
      value,
    }));
  }, [formatMessage]);
  const { locale: currentLocale } = useRouter();
  const { getLocale } = useCurrentLocale();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [membershipDuration, setMembershipDuration] = useState(1);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const optionsContainerRef = useClickOutsideElement<HTMLDivElement>(() =>
    setIsSelectOpen(false),
  );
  const { date, getNewExpirationDate, setDate } = useGroupHelpers();
  const { addGroupMemberMembership } = useGroupFirebase();
  const currentMembership =
    translatedMembershipDuration.find(
      (option) => option.value === membershipDuration,
    )?.label || '';
  const newExpirationDate = useMemo(
    () =>
      format(
        getNewExpirationDate(
          member.membershipPaymentInfo,
          member.membershipExpiryDate,
          membershipDuration,
        ),
        'd.M.yyyy.',
      ),
    [membershipDuration, member, date],
  );

  function getFlatpickrLocale() {
    switch (currentLocale) {
      case 'hr':
        return 'hr';
      default:
        return 'en';
    }
  }

  return (
    <>
      <p css={styles.title}>
        <FormattedMessage id="membership.extend-membership.title" />
      </p>
      <div css={styles.wrapper}>
        <p>
          <FormattedMessage id="membership.extend-membership.select-date" />
        </p>
        <div
          css={[styles.datePicker, isDatePickerOpen && styles.activeDatePicker]}
        ></div>
        <div css={dateStyles.calendarWrapper}>
          <Flatpickr
            value={date}
            options={{
              disableMobile: true,
              dateFormat: 'Y-m-d H:i',
              locale: getFlatpickrLocale(),
            }}
            css={dateStyles.input}
            onChange={([date]) => {
              setDate(date);
            }}
          />
          <Calendar viewBox="0 0 16 18" css={dateStyles.calendarIcon} />

          <div css={dateStyles.dateRange}>
            {format(date, 'MMM d, yyyy', {
              locale: getLocale(),
            })}{' '}
          </div>
        </div>
        <p>{format(date, 'd.M.yyyy.')}</p>
      </div>
      <div css={styles.wrapper}>
        <p css={styles.description}>
          <FormattedMessage id="membership.extend-membership.length" />
        </p>
        <div css={styles.dropdown}>
          <div css={styles.dropdown}>
            <div
              onClick={() => setIsSelectOpen(!isSelectOpen)}
              css={styles.select}
            >
              <span css={styles.selectedOption}>{currentMembership}</span>
              {isSelectOpen ? <ArrowIconDown /> : <ArrowIconUp />}
            </div>
          </div>
          <AnimatePresence>
            {isSelectOpen && (
              <motion.div
                css={styles.dropdownActiveContainer}
                ref={optionsContainerRef}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.15, ease: 'easeInOut' }}
              >
                {translatedMembershipDuration.map(({ value, label }) => (
                  <MembershipDurationOptions
                    key={value}
                    option={value}
                    label={label}
                    isActive={value === membershipDuration}
                    onSetSelectedOption={setMembershipDuration}
                    onClose={() => setIsSelectOpen(false)}
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <p css={styles.dueDateWrapper}>
        <FormattedMessage id="membership.extend-membership.length.description" />{' '}
        <FormattedMessage id="membership.extend-membership.length.description1" />{' '}
        <span css={styles.expirationDate}>{newExpirationDate}</span>
      </p>
      <div css={styles.flex}>
        <Button
          customStyles={styles.buttonConfirm}
          disabled={isLoading}
          onClick={async () => {
            await addGroupMemberMembership({
              groupId,
              memberEmail: member.email,
              membershipInfo: {
                datePaid: date.getTime(),
                numberOfMonthsPaid: membershipDuration,
                name: member.name,
                expiryDate: getNewExpirationDate(
                  member.membershipPaymentInfo,
                  member.membershipExpiryDate,
                  membershipDuration,
                ),
                price: 0,
              },
            });
            setCurrentStep('info');
          }}
        >
          <FormattedMessage id="membership.extend" />{' '}
        </Button>
        <Button
          customStyles={styles.buttonBack}
          onClick={() => setCurrentMembershipStep('info')}
        >
          <FormattedMessage id="membership.extend-membership.back" />
        </Button>
      </div>
    </>
  );
};

import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { display } from '@styles/display.styles';
import { breakpoints } from '@styles/variables.styles';

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16141bcc;
  overflow: hidden;
  z-index: 1000;
`;

const base = css`
  display: flex;
  border: 1px solid #f8f8f814;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;

  @media ${breakpoints.fromMed} {
    max-width: 80%;
  }
`;

const columnOne = css`
  ${display.overflowAuto};
  height: 100%;
  padding: 36px 32px;
  max-width: 600px;
  background-color: var(--color-background-2);

  @media ${breakpoints.fromMed} {
    min-width: 520px;
  }
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #ffffff;
`;

const description = css`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

const canceledBookingsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`;

const buttonContainer = css`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  button {
    min-width: max-content;
  }
`;

const confirmButton = css`
  ${button.primary};
  margin-top: 16px;
  background: var(--color-general-primary);
  border-radius: 32px;
  padding: 8px 28px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled {
    color: white;
    cursor: not-allowed;
  }
`;

const cancelButton = css`
  ${button.secondary};
  margin-top: 16px;
  background: transparent;
  border-radius: 32px;
  padding: 8px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c7c7d1;

  &:hover,
  &:focus,
  &:active {
  }
`;

const paintballContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  border: 1px solid var(--color-borderDark);
  border-radius: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const packageDescriptionContainer = css`
  display: flex;
  flex-direction: column;
`;

const packageTitle = css`
  color: white;
  font-family: 'HK Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
`;

const paintballHoursWrapper = css`
  color: var(--color-text-4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
`;

const numberOfPlayersContainer = css`
  display: flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background: rgba(244, 248, 247, 0.05);
  color: #c7c7d1;
  height: fit-content;
`;

export const styles = {
  base,
  wrapper,
  columnOne,
  title,
  description,
  confirmButton,
  canceledBookingsContainer,
  buttonContainer,
  cancelButton,
  paintballContainer,
  packageDescriptionContainer,
  packageTitle,
  paintballHoursWrapper,
  numberOfPlayersContainer,
};

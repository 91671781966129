import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const container = css`
  min-height: 100vh;
  ${grid.basic12col};
  gap: 0px;
  grid-auto-rows: auto;
  width: 100%;

  @media ${breakpoints.from1024} {
    gap: 40px;
  }
`;

const main = css`
  grid-column: 1 / 11;
`;

const wrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16141bcc;
  overflow: hidden;
  ${grid.basic12col};
  gap: 0px;
  padding: 0px;

  @media ${breakpoints.from1024} {
    gap: 40px;
  }
`;

export const styles = { main, container, wrapper };

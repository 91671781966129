import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const container = css`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  align-items: flex-start;
  padding: 20px 16px;
  gap: 10px;

  background: #24222a;
  color: #c7c7d1;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  border: 1px solid rgba(248, 248, 248, 0.08);
  border-radius: 10px;

  white-space: nowrap;
  text-overflow: ellipsis;

  transition: max-height 0.6s;
  overflow: hidden;
  height: max-content;

  &:hover > button:first-of-type {
    path {
      fill: var(--color-general-primary);
    }
  }
`;

const pastContainer = css`
  background: #1f1d24;
  opacity: 0.6;
`;

const headerContainer = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const cancelContainer = css`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const cancel = css`
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #eb5757;
  padding: 0;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: auto;
  margin-right: auto;

  &:hover {
    color: red;

    & > svg > path {
      fill: red;
    }
  }
`;

const bookingInfoWrapper = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  overflow: hidden;
`;

const bookingInfo = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;

  @media ${breakpoints.fromSml} {
    flex-direction: row;
    align-items: center;
  }
`;

const bookingDate = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const bookingTime = css`
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
`;

const sideInfo = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const name = css`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
  gap: 12px;
`;

const bookingInfoContainer = css`
  position: absolute;
  bottom: 12px;
  right: 16px;
`;

const pyramid = css`
  padding: 4px 12px;
  color: var(--color-general-primary);
  background-color: var(--color-background-4);
  border-radius: 12px;
  color: #ffac1c;
`;

const accordionButton = css`
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: transparent;
  border: none;
  width: 32px;
  height: 40px;

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    transition: all 0.15s ease-out;
  }

  &:hover path {
    fill: var(--color-general-primary);
  }
`;

export const styles = {
  container,
  headerContainer,
  cancelContainer,
  cancel,
  bookingInfo,
  bookingDate,
  bookingTime,
  pastContainer,
  name,
  pyramid,
  bookingInfoContainer,
  accordionButton,
  bookingInfoWrapper,
  sideInfo,
};

import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  position: fixed;
  background-color: #16141bcc;
  color: var(--color-text-6);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const modal = css`
  width: 100%;
  padding: 28px 24px;
  background-color: var(--color-background-2);
  border-radius: 8px;
  max-height: 80vh;

  @media ${breakpoints.fromMed} {
    min-width: 520px;
    max-width: 600px;
  }
`;

export const styles = {
  base,
  modal,
};

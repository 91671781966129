import { BookedHours } from '../models';

export interface DiscountConfig {
  discountOnNumberOfSlots: {
    [key: number]: DiscountInfo;
  };
}

interface DiscountInfo {
  amount: number;
  description: string;
  title: string;
}

export function mergeBookings(
  bookings: BookedHours[],
  discountConfig?: DiscountConfig | null,
  maxSlotsInBooking?: number,
  minSlotsInBooking?: number,
): BookedHours[] {
  // Group bookings by courtId
  const minSlots = minSlotsInBooking || 1;
  const groupedBookings = bookings.reduce((groups, booking) => {
    const key = booking.courtId;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(booking);
    return groups;
  }, {} as Record<string, BookedHours[]>);

  // Merge bookings within each group
  const mergedBookings = Object.values(groupedBookings).flatMap((group) => {
    const sortedBookings = [...group]
      .sort((a, b) => a.from - b.from)
      .map((booking) => ({
        ...booking,
        price: booking.price / minSlots,
      }));
    const result: BookedHours[] = [];
    let currentBooking = {
      ...sortedBookings[0],
      discountsApplied: [],
    };
    let slotIndex = 2;

    for (let i = 1; i < sortedBookings.length; i++) {
      if (
        currentBooking.to === sortedBookings[i].from &&
        currentBooking.courtId === sortedBookings[i].courtId
      ) {
        let nextBookingPrice = sortedBookings[i].price;
        let discountApplied = null;

        // Apply discount to the individual slots within the grouped booking

        if (discountConfig) {
          const bookingNumber = maxSlotsInBooking
            ? slotIndex % maxSlotsInBooking || maxSlotsInBooking
            : slotIndex;

          const discountInfo =
            discountConfig.discountOnNumberOfSlots[bookingNumber];
          if (discountInfo) {
            nextBookingPrice -= discountInfo.amount;
            discountApplied = discountInfo;
          }
        }

        currentBooking = {
          ...currentBooking,
          to: sortedBookings[i].to,
          price: currentBooking.price + nextBookingPrice,
          discountsApplied: discountApplied
            ? [...currentBooking.discountsApplied, discountApplied]
            : currentBooking.discountsApplied,
        };

        slotIndex++;
        continue;
      }

      result.push(currentBooking);
      currentBooking = { ...sortedBookings[i], discountsApplied: [] };
      slotIndex = 2; // Reset the slot index for the new grouped booking
    }

    result.push(currentBooking);

    return result;
  });

  mergedBookings.sort((a, b) => {
    if (a.from !== b.from) {
      return a.from - b.from;
    }
    return a.to - b.to;
  });

  return mergedBookings;
}

import { de, enUS, hr } from 'date-fns/locale';
import { useRouter } from 'next/router';

export function useCurrentLocale() {
  const { locale: currentLocale } = useRouter();

  function getLocale(): Locale {
    switch (currentLocale) {
      case 'hr':
        return hr;
      case 'de':
        return de;
      default:
        return enUS;
    }
  }

  return {
    currentLocale,
    getLocale,
  };
}

import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  min-height: 100vh;
  ${grid.basic12col};
  grid-column-gap: 0px;
  grid-auto-rows: auto;
  width: 100%;

  @media ${breakpoints.from1024} {
    grid-column-gap: 40px;
  }
`;

const main = css`
  grid-column: 1 / 11;
`;

const wrapper = css`
  ${grid.basic10col};
  gap: 0px;
  padding: 0;

  @media ${breakpoints.from1024} {
    grid-column-gap: 40px;
  }
`;

const toggleStyles = css`
  display: block;
  position: relative;
  width: 60px;
  height: 32px;
`;

const inputStyles = css`
  opacity: 0;
  width: 0;
  height: 0;
`;

const sliderStyles = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 28px;
  background-color: var(--color-background-4);
  opacity: 0.4;

  &:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: var(--color-icon-4);
    transition: 0.4s;
    border-radius: 24px;
  }

  &:after {
    position: absolute;
    content: '';
    -webkit-mask: url('/icons/trash-can.svg') no-repeat 50% 50%;
    mask: url('/icons/trash-can.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    height: 16px;
    width: 16px;
    right: 10px;
    bottom: 8px;
    transition: 0.4s;
    background-color: var(--color-icon-5);
  }
`;

const sliderCheckedStyles = css`
  ${sliderStyles};

  background-color: rgba(226, 65, 43, 0.2);
  opacity: 1;

  &:before {
    transform: translateX(26px);
    background-color: var(--color-error-utility);
  }

  &:after {
    background-color: var(--color-icon-7);
  }
`;

const destructiveWrapper = css`
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--color-text-5);
  font-size: 14px;
  line-height: 20px;

  span {
    min-width: max-content;
  }
`;

const spaceBetweenWeek = css`
  grid-column: 1 / span 10;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 32px;
`;

const spaceBetweenDay = css`
  ${spaceBetweenWeek}
  grid-column: 1 / span 10;
  max-width: 1160px;
`;

export const styles = {
  base,
  wrapper,
  toggleStyles,
  inputStyles,
  sliderStyles,
  sliderCheckedStyles,
  destructiveWrapper,
  spaceBetweenWeek,
  spaceBetweenDay,
  main,
};

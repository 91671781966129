import {
  useDefaultClaim,
  useIsPlayOffAdmin,
  useUserClaims,
} from '@modules/auth';
import { useFacility } from '@modules/facility';
import { AddNewFacilityModal } from '@modules/facility-management/components/facility-info-form/fragments/AddNewFacilityModal';
import { useModal, useNoScroll } from '@shared/hooks';
import { NewButton } from '@ui/Button';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CalendarIcon from 'public/icons/calendar_calendar.svg';
import ClockIcon from 'public/icons/clock-16.svg';
import AppDataIcon from 'public/icons/facility-16.svg';
import GroupIcon from 'public/icons/group.svg';
import IdIcon from 'public/icons/id_card.svg';
import ChartIcon from 'public/icons/line_chart_up.svg';
import SmartLockIcon from 'public/icons/lock.svg';
import TrashIcon from 'public/icons/trash-can.svg';
import Logo from 'public/logo/playoff-logo-68.svg';
import versionFile from 'public/version.json';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SidebarToggle } from '../sidebar-toggle/SidebarToggle';
import { AccordionWrapper } from './AccordionWrapper';
import { styles } from './styles';

export const AdminDashboardNavigationMobile: React.FC = () => {
  const facility = useFacility();
  const { pathname } = useRouter();
  const isPlayoffAdmin = useIsPlayOffAdmin();
  const isEmployee = pathname.includes('dashboard/employee');
  const { isModalOpen, openModal, closeModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const defaultClaim = useDefaultClaim();
  const claims = useUserClaims();
  const newRole = useMemo(
    () => claims?.find((claim) => claim?.facilityId === facility?.id)?.role,
    [claims, facility?.id],
  );

  function getRole() {
    if (defaultClaim?.role === 'playoff-admin') return 'playoff-admin';

    return newRole;
  }

  function isActive(routes: string[]) {
    if (routes.some((route) => route === pathname)) return styles.activeLink;

    return '';
  }

  useNoScroll(isOpen);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            css={styles.overlay}
          />
        )}
      </AnimatePresence>
      <motion.nav
        animate={{
          width: isOpen ? '300px' : '60px',
        }}
        transition={{
          duration: 0.3,
          ease: 'easeOut',
        }}
        css={styles.nav}
      >
        <div css={isOpen ? styles.accordionLogoOpen : styles.accordionLogo}>
          <AccordionWrapper isOpen={isOpen}>
            <Link href="/" css={styles.logo}>
              <Logo viewBox="0 0 68 16" />
            </Link>
          </AccordionWrapper>
          <SidebarToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
        </div>
        <ul css={styles.list}>
          {facility?.type === 'sport' && (
            <>
              <li>
                <Link
                  css={[
                    isOpen ? styles.linkOpen : styles.link,
                    isActive([
                      '/dashboard/playoff-admin/booking-calendar',
                      '/dashboard/admin/booking-calendar',
                      '/dashboard/employee/booking-calendar',
                    ]),
                  ]}
                  href={`/dashboard/${getRole()}/booking-calendar`}
                >
                  <CalendarIcon css={styles.icons} />
                  <AccordionWrapper isOpen={isOpen}>
                    <FormattedMessage id="admin-nav-booking-calendar" />
                  </AccordionWrapper>
                </Link>
              </li>
              <li>
                <Link
                  css={[
                    isOpen ? styles.linkOpen : styles.link,
                    isActive([
                      '/dashboard/playoff-admin/booking-overview',
                      '/dashboard/admin/booking-overview',
                      '/dashboard/employee/booking-overview',
                    ]),
                  ]}
                  href={`/dashboard/${getRole()}/booking-overview`}
                >
                  <ClockIcon css={styles.icons} />
                  <AccordionWrapper isOpen={isOpen}>
                    <FormattedMessage id="admin-nav-booking-overview" />
                  </AccordionWrapper>
                </Link>
              </li>
            </>
          )}
          {facility?.type === 'group' && (
            <li>
              <Link
                css={[
                  isOpen ? styles.linkOpen : styles.link,
                  isActive([
                    '/dashboard/playoff-admin/groups',
                    '/dashboard/admin/groups',
                    '/dashboard/employee/groups',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/groups`}
              >
                <GroupIcon css={styles.icons} />
                <AccordionWrapper isOpen={isOpen}>
                  <FormattedMessage id="admin-nav-groups" />
                </AccordionWrapper>
              </Link>
            </li>
          )}
        </ul>
        {!isEmployee && (
          <ul css={styles.list}>
            <li>
              <Link
                css={[
                  isOpen ? styles.linkOpen : styles.link,
                  isActive([
                    '/dashboard/playoff-admin/management',
                    '/dashboard/admin/management',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/management`}
              >
                <IdIcon css={styles.icons} />
                <AccordionWrapper isOpen={isOpen}>
                  <FormattedMessage id="admin-nav-management" />
                </AccordionWrapper>
              </Link>
            </li>
            <li>
              <Link
                css={[
                  isOpen ? styles.linkOpen : styles.link,
                  isActive([
                    '/dashboard/playoff-admin/analytics',
                    '/dashboard/admin/analytics',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/analytics`}
              >
                <ChartIcon css={styles.icons} />
                <AccordionWrapper isOpen={isOpen}>
                  <FormattedMessage id="admin-nav-analytics" />
                </AccordionWrapper>
              </Link>
            </li>
            {isPlayoffAdmin && (
              <>
                {isOpen ? (
                  <p css={[styles.sectionTitle]}>
                    <FormattedMessage id="admin-nav-playoff.dashboard" />
                  </p>
                ) : (
                  <hr css={styles.horizontalLine} />
                )}
                <ul css={styles.list}>
                  <li>
                    <Link
                      css={[
                        isOpen ? styles.linkOpen : styles.link,
                        isActive([
                          '/dashboard/playoff-admin/facility-management',
                        ]),
                      ]}
                      href={`/dashboard/playoff-admin/facility-management`}
                    >
                      <CalendarIcon css={styles.icons} />
                      <AccordionWrapper isOpen={isOpen}>
                        <FormattedMessage id="admin-nav-facility-management" />
                      </AccordionWrapper>
                    </Link>
                  </li>
                  <li>
                    <Link
                      css={[
                        isOpen ? styles.linkOpen : styles.link,
                        isActive(['/dashboard/playoff-admin/smart-lock']),
                      ]}
                      href={`/dashboard/playoff-admin/smart-lock`}
                    >
                      <SmartLockIcon css={styles.icons} />
                      <AccordionWrapper isOpen={isOpen}>
                        <FormattedMessage id="admin-nav-smart-lock" />
                      </AccordionWrapper>
                    </Link>
                  </li>
                  <li>
                    <Link
                      css={[
                        isOpen ? styles.linkOpen : styles.link,
                        isActive([
                          '/dashboard/playoff-admin/facility-deletion',
                        ]),
                      ]}
                      href={`/dashboard/playoff-admin/facility-deletion`}
                    >
                      <TrashIcon css={styles.icons} />
                      <AccordionWrapper isOpen={isOpen}>
                        <FormattedMessage id="admin-nav-facility-deletion" />
                      </AccordionWrapper>
                    </Link>
                  </li>
                  <li>
                    <Link
                      css={[
                        isOpen ? styles.linkOpen : styles.link,
                        isActive([
                          '/dashboard/playoff-admin/app-data-management',
                        ]),
                      ]}
                      href={`/dashboard/playoff-admin/app-data-management`}
                    >
                      <AppDataIcon css={styles.icons} />
                      <AccordionWrapper isOpen={isOpen}>
                        <FormattedMessage id="admin-nav-app-data-management" />
                      </AccordionWrapper>
                    </Link>
                  </li>
                  <li>
                    <Link
                      css={[
                        isOpen ? styles.linkOpen : styles.link,
                        isActive(['/dashboard/playoff-admin/playoff']),
                      ]}
                      href={`/dashboard/playoff-admin/playoff`}
                    >
                      <IdIcon css={styles.icons} />
                      <AccordionWrapper isOpen={isOpen}>
                        <FormattedMessage id="admin-nav-playoff-management" />
                      </AccordionWrapper>
                    </Link>
                  </li>
                  {isOpen && (
                    <li css={styles.addFacility}>
                      <NewButton
                        type="button"
                        variant="outline-green"
                        size="small"
                        onClick={openModal}
                      >
                        <FormattedMessage id="button.add-new-facility" />
                      </NewButton>
                    </li>
                  )}
                </ul>
              </>
            )}
          </ul>
        )}
        <div css={styles.footer}>
          <AccordionWrapper isOpen={isOpen}>
            <Link css={styles.logoWrapper} href="/">
              <Logo css={styles.logoSecondary} viewBox="0 0 68 16" />
              <span>{versionFile.currentVersion}</span>
            </Link>

            <div css={styles.footerLinks}>
              <Link
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.privacy-policy" />
              </Link>
              <Link
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.terms-of-service" />
              </Link>
            </div>
          </AccordionWrapper>
        </div>
        {isModalOpen && <AddNewFacilityModal closeModal={closeModal} />}{' '}
      </motion.nav>
    </>
  );
};

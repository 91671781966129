import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const header = css`
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 12px;
`;

const adminMain = css`
  min-height: calc(100vh - 180px);
  ${grid.basic50col};
  grid-auto-rows: auto;
`;

const historyContainer = css`
  max-height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const selectWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const monthlyLabel = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #a7a7a7;
`;

const monthSwitcher = css`
  display: flex;
  gap: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const currentMonth = css`
  color: #ffffff;
  cursor: pointer;
  border-bottom: 1px solid #ffffff;
`;

const pastMonth = css`
  color: #737373;
  cursor: pointer;
`;

const statisticsContainer = css`
  display: flex;
  gap: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const statisticsGroup = css`
  display: flex;
  flex-direction: column;
`;

const statisticsValue = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
`;

const statisticsLabel = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const graphsContainer = css`
  display: flex;
  gap: 64px;
  flex-direction: column;
  align-items: flex-end;

  @media ${breakpoints.from1024} {
    flex-direction: row;
  }
`;

const singleGraphWrapper = css`
  width: 100%;
  height: 100%;

  @media ${breakpoints.from1024} {
    width: 50%;
  }
`;

const reservationsGraphHeader = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const reservationNumber = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #a7a7a7;
`;

const reservationGraphSwitcher = css`
  font-size: 12px;
`;

const barGraphWrapper = css`
  width: 100%;
  margin-top: 80px;
`;

const hotspotsGraphHeader = css`
  display: flex;
  justify-content: space-between;
`;

export const monthlyStatisticsStyles = {
  header,
  historyContainer,
  adminMain,
  selectWrapper,
  monthlyLabel,
  monthSwitcher,
  statisticsContainer,
  statisticsGroup,
  statisticsValue,
  statisticsLabel,
  graphsContainer,
  singleGraphWrapper,
  reservationsGraphHeader,
  reservationNumber,
  reservationGraphSwitcher,
  barGraphWrapper,
  hotspotsGraphHeader,
  currentMonth,
  pastMonth,
};

import { bookingAtoms } from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { getTypedCollectionDocument } from '@modules/firebase';
import {
  QueryDocumentSnapshot,
  getDocs,
  limit,
  query,
  startAfter,
} from 'firebase/firestore';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FILTER_RESULTS } from '../const';
import { buildQuery } from '../utils';

const PAGE_LIMIT = 10;

interface BookingOverviewListenerData {
  statusFilter: string;
  threshold?: number;
}

export function useBookingOverviewListener({
  statusFilter,
  threshold = 200,
}: BookingOverviewListenerData) {
  const { locale } = useRouter();
  const facility = useFacility();
  const [hasMore, setHasMore] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [bookings, setBookings] = useRecoilState(bookingAtoms.bookings);
  const lastDocument = useRef<QueryDocumentSnapshot<DocumentData> | null>(null);
  const listeners = useRef<(() => void)[]>([]);

  /**Reset effect. When filter changes reset hook to starting state. */
  useEffect(() => {
    lastDocument.current = null;
    setHasMore(true);
    setBookings([]);
  }, [statusFilter, setBookings]);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      const shouldStop =
        !hasMore ||
        fetching ||
        innerHeight + scrollTop + threshold <= offsetHeight;

      if (shouldStop) return;

      setFetching(true);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetching, hasMore, threshold]);

  useEffect(() => {
    if (!fetching) return;
    let firebaseQuery = buildQuery({
      statusFilter,
      facilityId: facility?.id,
      locale,
    });

    /***Pagination */
    if (lastDocument.current) {
      firebaseQuery = query(firebaseQuery, startAfter(lastDocument.current));
    }

    let mounted = true;
    const queryWithLimit =
      FILTER_RESULTS[locale][3] !== statusFilter
        ? query(firebaseQuery, limit(PAGE_LIMIT))
        : firebaseQuery;

    getDocs(queryWithLimit).then((snapshot) => {
      if (mounted) {
        const bookings = snapshot.docs.flatMap((doc) =>
          getTypedCollectionDocument<Booking[]>(doc),
        );

        /***Repeated bookings */
        if (statusFilter === FILTER_RESULTS[locale][3]) {
          const groupedBookings: Record<string, Booking[]> = bookings
            .filter((booking) => booking.isRepeat)
            .reduce((groups, booking) => {
              const key = booking.repeatId;
              if (!groups[key]) {
                groups[key] = [];
              }
              groups[key].push(booking);
              return groups;
            }, {});

          const repeatedBookings: Booking[] = Object.values(groupedBookings)
            .flatMap((bookings) => bookings)
            .filter((booking: Booking) => {
              if (!booking.isFirstRepeat || booking.isRepeatCancelled) {
                return false;
              }

              /** Calculate end time of the last booking in the repeat duration time */
              const repeatBookingEndDate = new Date(booking.endTime);
              repeatBookingEndDate.setDate(
                repeatBookingEndDate.getDate() +
                  (booking.repeatDuration - 1) * 7,
              );

              return repeatBookingEndDate.getTime() > new Date().getTime();
            });

          setBookings((prevBookings) => [...prevBookings, ...repeatedBookings]);
        } else {
          setBookings((prevBookings) => [...prevBookings, ...bookings]);
        }
        lastDocument.current = snapshot.docs[snapshot.docs.length - 1];
        if (bookings?.length < PAGE_LIMIT) {
          setHasMore(false);
        }
        setFetching(false);
      }
    });

    return () => {
      mounted = false;
      listeners.current.forEach((unsubscribe) => unsubscribe());
      listeners.current = [];
    };
  }, [fetching, facility?.id, statusFilter, locale, bookings, setBookings]);

  return {
    bookings,
    fetching,
    setFetching,
  };
}

import { FieldValuesFromFieldErrors } from '@hookform/error-message';
import { getSelectStyles } from '@shared/components';
import { useSingleSelect } from '@shared/hooks';
import { FieldWrapper } from '@ui/FormInput';
import {
  Controller,
  FieldErrors,
  FieldName,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select from 'react-select';

interface Option<T> {
  value: T;
  label: string;
}

interface SingleSelectProps<TFieldValues extends FieldValues, T = string> {
  name: Path<TFieldValues>;
  labelId?: string;
  placeholderId?: string;
  options: Option<T>[];
  isRequired?: boolean;
  isSearchable?: boolean;
  onChangeHandler?: (value: T | null) => void;
  controlMinWidth?: number;
  registerOptions?: RegisterOptions<TFieldValues, Path<TFieldValues>>;
}

export const SingleSelect = <TFieldValues extends FieldValues, T = string>({
  name,
  labelId,
  placeholderId = 'input.select.placeholder',
  options,
  isRequired = false,
  isSearchable = true,
  registerOptions,
  controlMinWidth,
  onChangeHandler,
}: SingleSelectProps<TFieldValues, T>) => {
  const { formatMessage } = useIntl();

  const { form, errors, handleFocus, handleSelectChange, getValue } =
    useSingleSelect<TFieldValues, T>({
      options,
      onChangeHandler,
    });

  return (
    <FieldWrapper
      name={
        name as unknown as FieldName<
          FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>
        >
      }
      labelId={labelId}
      isRequired={isRequired}
      errors={errors}
    >
      <Controller<TFieldValues>
        name={name}
        control={form.control}
        rules={registerOptions}
        defaultValue={form.getValues(name) || null}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            onChange={handleSelectChange(field)}
            value={getValue(field.value)}
            onFocus={handleFocus}
            styles={getSelectStyles(Boolean(errors[name]), controlMinWidth)}
            id={name}
            placeholder={formatMessage({
              id: placeholderId,
            })}
            noOptionsMessage={() =>
              formatMessage({
                id: 'input.select.noResults',
              })
            }
            isSearchable={isSearchable}
            className="w-full"
            classNamePrefix="react-select"
          />
        )}
      />
    </FieldWrapper>
  );
};

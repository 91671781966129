import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';
import { format } from 'date-fns';
interface List {
  from: Date;
}

const base = css`
  position: relative;
  grid-column: 1 / span 10;
  display: flex;
  gap: 8px;
  width: 100%;

  @media ${breakpoints.fromMed} {
    grid-column: 1 / span 5;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 1 / span 4;
  }
`;

const generateGridTemplateRows = (listArray?: List[]) => {
  const rows = listArray?.map(
    ({ from }) => `[time-${format(from, 'HHmm')}] 94px`,
  );

  return rows?.join('\n');
};

const wrapper = css`
  height: 100%;
  width: 100%;
`;

const gradient = css`
  display: block;
  background: linear-gradient(180deg, #16141b 0%, rgba(24, 24, 29, 0) 100%);
  height: 4px;
  width: 100%;
`;

const date = css`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--color-background-1);
  color: var(--color-text-4);
  font-family: HK Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 0;
  text-transform: capitalize;
`;

const dateActive = css`
  ${date}
  color: var(--color-general-primary);
`;

const list = (list: List[]) => css`
  display: grid;
  grid-template-rows: ${generateGridTemplateRows(list)};
`;

export const styles = { base, gradient, wrapper, date, dateActive, list };

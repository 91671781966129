import { useFacility } from '@modules/facility';
import { getItemRoleLabel, useDeleteRole } from '@modules/users';
import { useClickOutsideElement } from '@shared/hooks';
import { NewButton } from '@ui/Button';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

interface Props {
  email: string;
  role: Role;
  closeModal: () => void;
}

export const DeleteRoleModal: React.FC<Props> = ({
  email,
  role,
  closeModal,
}) => {
  const facility = useFacility();

  const [loading, setLoading] = useState(false);
  const closeRef = useClickOutsideElement<HTMLDivElement>(closeModal);
  const { deleteRole } = useDeleteRole();

  async function handleSubmit() {
    setLoading(true);
    await deleteRole({
      email,
      role,
      facilityId: facility?.id || '',
    });
    closeModal();
    setLoading(false);
  }

  return (
    <AnimatePresence>
      <motion.div
        css={styles.wrapper}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div css={styles.base} ref={closeRef}>
          <div css={styles.columnOne}>
            <h2 css={styles.title}>
              <FormattedMessage id="delete-role.modal.title" />
            </h2>
            <h3 css={styles.description}>
              <FormattedMessage id="delete-role.modal.description" />
            </h3>
            <h3 css={styles.confirmDescription}>
              <FormattedMessage id="delete-role.modal.confirm.description" />
            </h3>
            <div css={styles.card}>
              <span css={styles.cardEmail}>{email}</span>
              <div css={styles.cardRow}>
                <span css={styles.cardRole}>{getItemRoleLabel(role)}</span>
                <span css={styles.cardFacility}>{facility?.name || ''}</span>
              </div>
            </div>

            <div className="flex mt-24 gap-12">
              <NewButton
                type="button"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                <FormattedMessage id="delete-role.modal.confirm" />
              </NewButton>
              <NewButton variant="ghost" type="button" onClick={closeModal}>
                <FormattedMessage id="delete-role.modal.cancel" />
              </NewButton>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

import { useIsDayView, useSetIsDayView } from '@modules/booking-new';
import { useScreenWidth } from '@shared/hooks/useScreenWidth';
import { FC, useEffect } from 'react';
import { BookingDaySlots } from '../booking-day-slots/BookingDaySlots';
import { BookingWeekSlots } from '../booking-week-slots/BookingWeekSlots';

export const CalendarView: FC = () => {
  const isDay = useIsDayView();
  const screenWidth = useScreenWidth();
  const setIsDay = useSetIsDayView();

  useEffect(() => {
    if (screenWidth < 1024) {
      setIsDay(true);
    }
  }, [screenWidth, setIsDay]);

  return <>{isDay ? <BookingDaySlots /> : <BookingWeekSlots />}</>;
};

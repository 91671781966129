import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const containerStyles = css`
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
  ${grid.basic12col};
  gap: 0px;
  padding: 0;

  @media ${breakpoints.from1024} {
    gap: 40px;
  }
`;

const titleStyles = css`
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
`;

const tableStyles = css`
  border-collapse: collapse;
  grid-column: 1 / 13;
  width: 100%;

  @media ${breakpoints.to1024} {
    display: block;
    overflow-x: auto;

    /* Optional: better touch scrolling */
    -webkit-overflow-scrolling: touch;
  }

  tr {
    @media ${breakpoints.to1024} {
      white-space: nowrap;
      padding-right: 16px;
    }
  }

  th {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 3px;
    padding-bottom: 12px;
    padding-right: 16px;
    text-align: left;
    color: var(--color-text-gray);
    text-transform: uppercase;

    @media (max-width: ${breakpoints.from1024.replace('@media ', '')}) {
      white-space: nowrap;
      padding-right: 16px;
    }
  }

  td {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    border-bottom: 1px solid #f8f8f814;
    color: var(--color-text-5);
    padding: 12px 0;

    @media ${breakpoints.to1024} {
      white-space: nowrap;
      padding-right: 16px;
    }

    &:last-child {
      text-align: right;
    }

    button {
      padding: 4px 16px;
      border-radius: 32px;
      background-color: transparent;
      border: 1px solid gray;
      color: var(--color-text-5);
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const styles = {
  containerStyles,
  titleStyles,
  tableStyles,
};

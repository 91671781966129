import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const layout = css`
  display: flex;
`;

const sidebar = css`
  display: none;
  position: relative;
  height: 100%;
  border-right: 1px solid #505050;
  padding: 26px 8px 40px;
  grid-column: 1 / 2;
  min-width: 40px;
  overflow-x: visible;
  z-index: 1;

  @media ${breakpoints.from1024} {
    display: block;
    grid-column: 1 / 4;
    padding: 26px 16px 40px 0;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 1 / 3;
  }
`;

const sidebarClosed = css`
  grid-column: 1 / 2;
  overflow: hidden;
`;

const main = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  min-height: 100vh;
`;

const adminAnalyticsMain = css`
  grid-column: 13 / 46;
`;

const playOffManagementMain = css`
  min-height: calc(100vh - 170px);
  grid-column: 13 / 46;
`;

const title = css`
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-primary-SemiBold);
  color: var(--color-text-6);

  @media ${breakpoints.toMed} {
    max-width: 100px;
  }
`;

const tickets = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1/9;
  grid-gap: 40px;
`;

const submisionType = css`
  padding: 4px 16px;
  border: 1px solid var(----color-border-6);
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
`;

const submissionStatus = css`
  position: relative;
  padding-left: 12px;
  text-transform: capitalize;
  color: var(--color-info);
  font-size: 14px;
  line-height: 16px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    transform: translateY(-50%);
    top: 50%;
    left: -8px;
    border-radius: 100px;
    color: var(--color-info);
  }
`;

const submissionStatusSuccess = css`
  &:before {
    background: var(--color-success);
  }
`;
const submissionStatusReject = css`
  &:before {
    background: var(--color-error);
  }
`;

const speakerName = css`
  font-size: 14px;
  line-height: 16px;
`;

const speakerPosition = css``;

const speakerLocation = css``;

const header = css`
  grid-column: 1 / 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
  gap: 8px;

  @media ${breakpoints.fromSml} {
    gap: 20px;
  }
`;

const description = css`
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: #a7a7a7;
  margin-bottom: 36px;
`;

const facilityInfo = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  padding: 4px 16px;

  border-radius: 28px;
  border: 1px solid var(--color-border-cardBorder);

  &:hover {
    border: 1px solid var(--color-border-6);
  }

  & path {
    fill: var(--color-icon-6);
  }
`;

const facilityName = css`
  margin-left: 12px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-text-6);
`;

const activeDropdown = css`
  border: 1px solid var(--color-border-6);
`;

const facilityPicker = css`
  width: 100%;
  height: auto;

  padding: 8px 12px 8px 16px;
`;

const logout = css`
  display: inline-block;
  margin-top: 60px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  cursor: pointer;
  padding-left: 20px;

  &:hover {
    color: white;
  }

  @media ${breakpoints.from1024} {
    padding: 0;
  }
`;

const wrapper = css`
  margin-top: 26px;
  grid-column: 1 / 13;
  ${grid.basic10col}
  grid-column-gap: 0px;
  padding: 0 0 0 60px;

  @media ${breakpoints.from1024} {
    grid-column: 4 / 13;
    grid-column-gap: 40px;
    padding: 0;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 3 / 13;
  }
`;

const languageBtn = (isActive: boolean) => css`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: var(--color-background-2);
  border: none;
  padding: 8px 16px;
  border-radius: 28px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-out;
  max-width: max-content;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;

    @media ${breakpoints.toSml} {
      display: none;
    }
  }

  &:hover,
  &:focus {
    background-color: #2a282f;
    transition: all 0.2s ease-out;
  }

  ${isActive &&
  css`
    background-color: #2a282f;
    transition: all 0.2s ease-out;
  `}
`;

const langAndDropdown = css`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
`;

export const adminDashboardStyles = {
  layout,
  sidebar,
  sidebarClosed,
  main,
  title,
  tickets,
  submisionType,
  submissionStatus,
  submissionStatusSuccess,
  submissionStatusReject,
  speakerName,
  speakerPosition,
  languageBtn,
  speakerLocation,
  header,
  description,
  adminAnalyticsMain,
  playOffManagementMain,
  facilityInfo,
  facilityName,
  activeDropdown,
  facilityPicker,
  logout,
  wrapper,
  langAndDropdown,
};
